import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import TokenService from "../../utils/token-service";

const getAssignedBy = (seperator) => {
  return TokenService.getUserRole() === "employee"
    ? (seperator ? seperator : "?") + "assigned_by=" + TokenService.getUserID()
    : "";
};
const insertUpdateClient = async (state) => {
  try {
    const { params, insertUpdateFlag, client_id } = state;

    const API_NAME =
      insertUpdateFlag === 0
        ? "client/createClient"
        : `client/updateClientById/${client_id}`;
    const data = await API.post(`${API_NAME}`, params);
    return data;
  } catch (error) {
    return [];
  }
};

const updateRegisteredClientStatus = async (state) => {
  try {
    const data = await API.post(`client/updateRegisteredClientStatus`, state);
    return data;
  } catch (error) {
    return [];
  }
};

const getActiveClients = createAsyncThunk(
  "client/getActiveClientsStatus",
  async () => {
    try {
      let clientMaster = [];
      const {
        data: { data },
      } = await API.get(`client/getAllClient?active=true`);
      if (data?.length > 0) {
        clientMaster = data.map(({ client_id, first_name, last_name }) => {
          return { id: client_id, value: `${first_name} ${last_name}` };
        });
      }
      return { data, clientMaster };
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getDashboardCardDetails = createAsyncThunk(
  "client/getDashboardCardDetailsStatus",
  async ({ user_id }) => {
    try {
      const {
        data: { data },
      } = await API.get(
        `client/dashboardCardDetails${
          user_id ? "?user_id=" + user_id : ""
        }${getAssignedBy(user_id && "&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getRegisteredClients = createAsyncThunk(
  "client/getRegisteredClientsStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(`client/getAllClient?registered=true`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getAllClients = createAsyncThunk(
  "client/getAllClientsStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(
        `client/getAllClient?overAll=true${getAssignedBy("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getSearchClient = createAsyncThunk(
  "client/getSearchClientStatus",
  async (state) => {
    try {
      const {
        data: { data },
      } = await API.post(`client/getSearchClient${getAssignedBy("?")}`, state);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);
// for reports
const getOverAllClients = createAsyncThunk(
  "client/getOverAllClientsStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(`client/getAllClient`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getIndividualClients = createAsyncThunk(
  "client/getIndividualClientsStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(
        `client/getAllClient?individual=true${getAssignedBy("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getBusinessClients = createAsyncThunk(
  "client/getBusinessClientsStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(
        `client/getAllClient?business=true${getAssignedBy("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

export {
  insertUpdateClient,
  updateRegisteredClientStatus,
  getActiveClients,
  getSearchClient,
  getAllClients,
  getRegisteredClients,
  getDashboardCardDetails,
  getOverAllClients,
  getIndividualClients,
  getBusinessClients,
};
