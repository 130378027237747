import React, { useEffect, useState, useMemo } from "react";
import { Table, FrameBox } from "../../components";
import { Grid, Paper, Avatar, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { dateFormatter } from "../../utils/common";
import _JSON from "./structure";
import { Invoice, Handshake, RegisterClient, Payment } from "../../images";
import { makeStyles } from "@material-ui/core/styles";
import "./reports.scss";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "20%",
      flexBasis: "20%",
    },
    // [theme.breakpoints.only("sm")]: {
    //   maxWidth: "33.3%",
    //   flexBasis: "33.3%",
    // },
  },
}));

const Reports = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const STRUCTURE_JSON = { ..._JSON };
  const [selectedReport, setSelectedReport] = useState({
    key: "",
    data: [],
  });
  const [tabelData, setTableData] = useState({
    "Registration reports": [],
    "Collection/invoice report": [],
    "Revenue by preparer (Employee)": [],
    "Contract report": [],
  });
  console.log(selectedReport, STRUCTURE_JSON[selectedReport], "selectedReport");
  const {
    client: { overAllClients, businessClients, individualClients },
    invoice: { allInvoices, collectionInvoices },
    contract: { allContracts },
  } = useSelector((state) => state);

  const icons = [RegisterClient, Invoice, Payment, Handshake];

  // useMemo(() => {
  //   const registrationData = overAllClients.map((data) => {
  //     const {
  //       first_name,
  //       last_name,
  //       phone,
  //       email,
  //       employee,
  //       source,
  //       client_type,
  //       branch_office,
  //       status,
  //       createdAt,
  //       client_id,
  //     } = data;
  //     return {
  //       createdAt: dateFormatter(createdAt),
  //       client_id,
  //       name: `${first_name} ${last_name}`,
  //       email,
  //       phone,
  //       employee,
  //       branch_office,
  //       client_type,
  //       status: status ? "Active " : "Inactive",
  //       source,
  //     };
  //   });
  //   setTableData((prev) => ({
  //     ...prev,
  //     "Registration reports": registrationData,
  //   }));
  // }, [overAllClients]);

  // useMemo(() => {
  //   const invoiceData = collectionInvoices.map((data) => {
  //     const {
  //       bill_to_first_name,
  //       bill_to_last_name,
  //       bill_to_phone,
  //       bill_to_email,
  //       bill_from_first_name,
  //       bill_from_last_name,
  //       client_type,
  //       branch_office,
  //       status,
  //       client_id,
  //       invoice_no,
  //       total,
  //       invoice_date,
  //     } = data;
  //     return {
  //       createdAt: dateFormatter(invoice_date),
  //       client_id,
  //       bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
  //       invoice_no,
  //       bill_to_email,
  //       bill_to_phone,
  //       bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
  //       branch_office: branch_office || "",
  //       client_type: client_type || "",
  //       total,
  //       status: status ? "Paid " : "Pending",
  //       payment_date: "",
  //       mode_of_payment: "",
  //     };
  //   });
  //   setTableData((prev) => ({
  //     ...prev,
  //     "Collection/invoice report": invoiceData,
  //   }));
  // }, [collectionInvoices]);

  // useMemo(() => {
  //   const invoiceData = allInvoices.map((data) => {
  //     const {
  //       bill_to_first_name,
  //       bill_to_last_name,
  //       bill_to_phone,
  //       bill_to_email,
  //       bill_from_first_name,
  //       bill_from_last_name,
  //       client_type,
  //       branch_office,
  //       status,
  //       client_id,
  //       invoice_no,
  //       total,
  //       invoice_date,
  //     } = data;
  //     return {
  //       createdAt: dateFormatter(invoice_date),
  //       client_id,
  //       bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
  //       invoice_no,
  //       bill_to_email,
  //       bill_to_phone,
  //       bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
  //       branch_office: branch_office || "",
  //       client_type: client_type || "",
  //       total,
  //       status: status ? "Paid " : "Pending",
  //       payment_date: "",
  //       mode_of_payment: "",
  //     };
  //   });
  //   setTableData((prev) => ({
  //     ...prev,
  //     "Revenue by preparer (Employee)": invoiceData,
  //   }));
  // }, [allInvoices]);

  // useMemo(() => {
  //   const invoiceData = individualClients.map((data) => {
  //     const {
  //       first_name,
  //       last_name,
  //       phone,
  //       email,
  //       employee,
  //       source,
  //       client_type,
  //       branch_office,
  //       status,
  //       createdAt,
  //       client_id,
  //     } = data;
  //     return {
  //       createdAt: dateFormatter(createdAt),
  //       client_id,
  //       name: `${first_name} ${last_name}`,
  //       email,
  //       phone,
  //       employee,
  //       branch_office,
  //       client_type,
  //       status: status ? "Active " : "Inactive",
  //       source,
  //     };
  //   });
  //   setTableData((prev) => ({
  //     ...prev,
  //     "Client list- Individual": invoiceData,
  //   }));
  // }, [individualClients]);

  // useMemo(() => {
  //   const invoiceData = businessClients.map((data) => {
  //     const {
  //       first_name,
  //       last_name,
  //       phone,
  //       email,
  //       employee,
  //       source,
  //       client_type,
  //       branch_office,
  //       status,
  //       createdAt,
  //       client_id,
  //     } = data;
  //     return {
  //       createdAt: dateFormatter(createdAt),
  //       client_id,
  //       name: `${first_name} ${last_name}`,
  //       email,
  //       phone,
  //       employee,
  //       branch_office,
  //       client_type,
  //       status: status ? "Active " : "Inactive",
  //       source,
  //     };
  //   });
  //   setTableData((prev) => ({
  //     ...prev,
  //     "Client list- Business": invoiceData,
  //   }));
  // }, [businessClients]);

  // useMemo(() => {
  //   const contractData = allContracts.map((data) => {
  //     const {
  //       bill_to_first_name,
  //       bill_to_last_name,
  //       bill_to_phone,
  //       bill_to_email,
  //       bill_from_first_name,
  //       bill_from_last_name,
  //       client_type,
  //       branch_office,
  //       status,
  //       client_id,
  //       contract_no,
  //       total,
  //       contract_date,
  //     } = data;
  //     return {
  //       createdAt: dateFormatter(contract_date),
  //       client_id,
  //       bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
  //       contract_no,
  //       bill_to_email,
  //       bill_to_phone,
  //       bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
  //       branch_office,
  //       client_type,
  //       total,
  //       status: status ? "Approved " : "Pending",
  //       approval_date: "",
  //     };
  //   });
  //   setTableData((prev) => ({
  //     ...prev,
  //     "Contract report": contractData,
  //   }));
  // }, [allContracts]);

  const onChange = (value, key, label) => {
    //TODO:: OnFilterChange functionality
  };

  const reports = Object.keys(STRUCTURE_JSON).map((report_key, index) => {
    return {
      id: report_key,
      value: report_key,
      icons: icons[index],
    };
  });

  const onChangeData = (selectedId) => {
    const returnAPIS = STRUCTURE_JSON[selectedId].map((item) => item.API);
    for (let i = 0; i <= returnAPIS.length - 1; i++) {
      const DYNAMIC_API = returnAPIS[i];
      DYNAMIC_API && dispatch(DYNAMIC_API());
    }
  };

  const onChangeReport = (selectedId) => {
    onChangeData(selectedId);
    setSelectedReport({
      key: selectedId,
      data: STRUCTURE_JSON[selectedId],
    });
  };

  return (
    <FrameBox title="Reports">
      <Grid container spacing={1}>
        {reports?.map((option, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            classes={{ item: classes.gridItem }}
          >
            <Paper
              key={index}
              className="report_card"
              onClick={() => onChangeReport(option.id)}
            >
              <div className="notify-tile">
                <Divider
                  orientation="vertical"
                  flexItem
                  className={`divider ${
                    option.id === selectedReport.key && "show"
                  }`}
                />
                <Avatar alt="" src={option.icons} className="notify-avatar" />
                <div className="message-tile">
                  <div className="notify-message notify-all-message">
                    {option.value}
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3} className="paddingTop">
        {selectedReport.data.map((report_data) => {
          return (
            <Grid item xs={12}>
              <Table
                showExporter
                variant="small"
                title={report_data.label}
                header={report_data.headers}
                data={tabelData[report_data.label] || []}
                filters={report_data.filters}
                filterCallback={(value, key) =>
                  onChange(value, key, report_data.label)
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </FrameBox>
  );
};

export default Reports;
