import axios from "axios";
import TokenService from "../utils/token-service";
import { API_URL } from "../utils/baseUrl";
import { Loader, Toaster, getUserTypeID } from "../utils/common";

function responseHandler(response) {
  if (response.status === 200 && response.data.status === 1) {
    Toaster.success(response.data.message);
    return response.data.data;
  } else if (response.status === 200 && response.data.status === 0) {
    Toaster.warning(response.data.message);
    return false;
  } else {
    Toaster.error("Something went wrong, please try again");
  }
}

const instance = axios.create({
  baseURL: API_URL,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});

instance.interceptors.request.use(
  (config) => {
    Loader(true);
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["x-access-token"] = token; // for Node.js Express back-end
      config.headers["user-type"] = getUserTypeID();
      config.headers["user-id"] = localStorage.getItem("user_id");
    }
    return config;
  },
  (error) => {
    Loader(false);

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    Loader(false);
    return res;
  },
  async (err) => {
    Loader(false);
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/auth/refreshtoken", {
            requestToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance({
            ...originalConfig,
            headers: {
              ...originalConfig.headers,
              "x-access-token": TokenService.getLocalAccessToken(),
            },
            sent: true,
          });
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 403) {
        // navigate("/login");
        Toaster.warning(err.response.data.message);
      }
    } else if (err.code === "ERR_NETWORK") {
      Toaster.warning("Check your connection and try again");
    }
    return Promise.reject(err);
  }
);

export default instance;
