import API from "../../utils/api";

const apiType = (type) => {
  switch (type) {
    case "forgetAuth":
      return "auth/forgot-password";
    case "resetAuth":
      return "auth/reset-password";
    case "newAuth":
      return "auth/change-password";
    default:
      return;
  }
};

const authService = async (payload, type) => {
  console.log("this is reset password params", payload , type)
  try {
    const api = apiType(type);
    console.log("this is api data", api)
    return await API.post(`${api}`, payload).then((response) => {
      if (response.status === 200 && response.data.status === 1)
        return "Success";
      else return response.data.message;
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export { authService };
