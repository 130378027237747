import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import {
  Document,
  Dashboard,
  Login,
  SignUp,
  CreateContract,
  Client,
  ContractProposal,
  Employee,
  ContractPayment,
  Profile,
  ReferralProgram,
  ServiceOffers,
  ForgetPassword,
  ResetPassword,
  ChangePassword,
  AllNotifications,
  CreateTimeSheet,
  TimeSheetDashboard,
  Reports,
  MangeClientRole,
} from "./pages";
import "./App.css";
import PrivateRoute from "../src/route/privateRoute";
import { AppLoader } from "./components";
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
const App = () => {
  return (
    <>
      <AppLoader />
      <Router>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forget_password" element={<ForgetPassword />} />
          {/* <Route path="/reset_password/:id" element={<ResetPassword />} /> */}
          <Route path="/reset_password/" element={<ResetPassword />} />
          
          <Route path="/finlo" element={<PrivateRoute />}>
            <Route path="/finlo/change_password" element={<ChangePassword />} />
            <Route path="/finlo/dashboard" element={<Dashboard />} />
            <Route path="/finlo/documents" element={<Document />} />
            <Route path="/finlo/employees" element={<Employee />} />
            <Route path="/finlo/client" element={<Client />} />
            <Route path="/finlo/reports" element={<Reports />} />
            <Route
              path="/finlo/contract_proposal"
              element={<ContractProposal />}
            />
            <Route path="/finlo/payment" element={<ContractPayment />} />
            <Route path="/finlo/profile" element={<Profile />} />
            <Route path="/finlo/referral" element={<ReferralProgram />} />
            <Route
              path="/finlo/invoice_contract"
              element={<CreateContract />}
            />
            <Route path="/finlo/service_offers" element={<ServiceOffers />} />
            <Route
              path="/finlo/create_timesheet"
              element={<CreateTimeSheet />}
            />
            <Route
              path="/finlo/timesheet_dashboard"
              element={<TimeSheetDashboard />}
            />
            <Route path="/finlo/notifications" element={<AllNotifications />} />
            <Route
              path="/finlo/manage_client_role"
              element={<MangeClientRole />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
