import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.css";
import { ToastContainer } from "react-toastify";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { NotificationContext } from "../src/utils/common";

const Index = () => {
  const [notifications, setNotifications] = useState([]);

  return (
    <>
      <Provider store={store}>
        <NotificationContext.Provider
          value={{ notifications, setNotifications }}
        >
          <App />
        </NotificationContext.Provider>
      </Provider>
      <ToastContainer />
    </>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
