import React, { useState } from "react";
import { Labelbox } from "../../components";
import { useNavigate } from "react-router-dom";
import ValidationLibrary from "../../utils/validationfunction";
import "./forget_password.scss";
import { AUTH_ACTION } from "../../redux/action";
import { Toaster } from "../../utils/common";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    email: {
      type: "text",
      labelname: "Email Address",
      value: "",
      validation: [{ name: "required" }, { name: "email" }],
      error: null,
      errmsg: null,
    },
  });

  const handleChange = (value, fieldKey) => {
    const errorcheck = ValidationLibrary.checkValidation(
      value,
      inputs[fieldKey].validation
    );
    inputs[fieldKey].value = value;
    inputs[fieldKey].error = !errorcheck.state;
    inputs[fieldKey].errmsg = errorcheck.msg;
    setInputs({ ...inputs });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errorList = Object.keys(inputs).filter((fieldKey) => {
        const errorcheck = ValidationLibrary.checkValidation(
          inputs[fieldKey].value,
          inputs[fieldKey].validation
        );
        inputs[fieldKey].error = !errorcheck.state;
        inputs[fieldKey].errmsg = errorcheck.msg;
        return !errorcheck.state;
      });
      setInputs({ ...inputs });
      if (!errorList.length) {
        const status = await AUTH_ACTION.authService(
          { email: inputs.email.value },
          "forgetAuth"
        );
        if (status === "Success") {
          Toaster.success("Verification mail has been sent");
          navigate("/");
        } else Toaster.error(status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div
        className="signup_container align_column"
        style={{ height: "100vh" }}
      >
        <div
          className="signup_block align_column credential_card"
          style={{ height: "auto" }}
        >
          <div className="signup_info credential_title">
            <h2 className="welcome" style={{color: "#1C74BC"}}>Forgot Password ?</h2>
            <h3>Enter the email associated with your account</h3>
          </div>
          <form action="" className="signup-form input-warpper">
            <div className="input_block">
              {Object.keys(inputs)?.map((fieldKey) => (
                <Labelbox
                  type={inputs[fieldKey].type}
                  labelname={inputs[fieldKey].labelname}
                  value={inputs[fieldKey].value}
                  changeData={(value) => handleChange(value, fieldKey)}
                  error={inputs[fieldKey].error}
                  errmsg={inputs[fieldKey].errmsg}
                />
              ))}
            </div>
            <center>
              <button
                className="signin_button action_btn"
                onClick={handleSubmit}
              >
                Send Verification
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
