const billed_from_keys = [
  "bill_from_first_name",
  "bill_from_last_name",
  "bill_from_email",
  "bill_from_address_1",
  "bill_from_address_2",
  "bill_from_phone",
  "bill_from_country",
  "bill_from_state",
  "bill_from_city",
  "bill_from_zipcode",
];
const billed_from_values = [
  "first_name",
  "last_name",
  "email",
  "address_1",
  "address_2",
  "phone",
  "country_id",
  "state_id",
  "city_id",
  "zipcode",
];

const billed_to_keys = [
  "bill_to_first_name",
  "bill_to_last_name",
  "bill_to_email",
  "bill_to_business_name",
  "bill_to_business_type",
  "bill_to_address_1",
  "bill_to_address_2",
  "bill_to_phone",
  "bill_to_country",
  "bill_to_state",
  "bill_to_city",
  "bill_to_zipcode",
];
const billed_to_business_values = [
  "first_name",
  "last_name",
  "email",
  "business_name",
  "business_type",
  "business_address_1",
  "business_address_2",
  "business_phone",
  "business_country",
  "business_state",
  "business_city_id",
  "business_zipcode",
];
const billed_to_personal_values = [
  "first_name",
  "last_name",
  "email",
  "",
  "",
  "address_1",
  "address_2",
  "phone",
  "country",
  "state",
  "city_id",
  "zipcode",
];
const editKeys = [
  "due_date",
  "description",
  "billed_by",
  "billed_to",
  "sub_total",
  "discount_type",
  "discount_status",
  "discount",
  "total",
  ...billed_from_keys,
  ...billed_to_keys,
];
export {
  billed_from_keys,
  billed_from_values,
  billed_to_keys,
  billed_to_business_values,
  billed_to_personal_values,
  editKeys,
};
