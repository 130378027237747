import { createSlice } from "@reduxjs/toolkit";
import {
  getActiveEmployees,
  getAllEmployees,
  getEmployee,
  getActiveManagers,
} from "../action/employeeAction";

const initialState = {
  activeEmployees: [],
  allEmployees: [],
  getEmployee: {},
  activeManagers: [],
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getActiveEmployees.fulfilled, (state, action) => {
      // Add user to the state array
      state.activeEmployees = action.payload;
    });
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      // Add user to the state array
      state.getEmployee = action.payload;
    });
    builder.addCase(getAllEmployees.fulfilled, (state, action) => {
      // Add user to the state array
      state.allEmployees = action.payload;
    });
    builder.addCase(getActiveManagers.fulfilled, (state, action) => {
      // Add user to the state array
      state.activeManagers = action.payload;
    });
  },
});

export default employeeSlice.reducer;
