import React, { useState } from "react";
import "./signup.css";
import { Labelbox } from "../../components";
import { useNavigate } from "react-router-dom";
import { CLIENT_ACTION } from "../../redux/action";
import ValidationLibrary from "../../utils/validationfunction";
import { encryptPassword, Toaster } from "../../utils/common";

const SignupPage = () => {
  const [inputs, setInputs] = useState({
    first_name: {
      type: "text",
      labelname: "First Name",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    last_name: {
      type: "text",
      labelname: "Last Name",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    email: {
      type: "text",
      labelname: "Email",
      value: "",
      validation: [{ name: "required" }, { name: "email" }],
      error: null,
      errmsg: null,
    },
    phone: {
      type: "text",
      labelname: "Phone Number",
      value: "",
      validation: [{ name: "mobile" }],
      error: null,
      errmsg: null,
    },
    password: {
      type: "text",
      labelname: "Password",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      password: true,
    },
    confirmPassword: {
      type: "text",
      labelname: "Confirm Password",
      value: "",
      validation: [
        { name: "required" },
        { name: "comparePassword", compareText: "" },
      ],
      error: null,
      errmsg: null,
      password: true,
    },
  });

  const navigate = useNavigate();

  const handleChange = (value, fieldKey) => {
    if (fieldKey === "confirmPassword")
      inputs[fieldKey].validation[1].compareText = inputs.password.value;

    const errorcheck = ValidationLibrary.checkValidation(
      value,
      inputs[fieldKey].validation
    );
    inputs[fieldKey].value = value;
    inputs[fieldKey].error = !errorcheck.state;
    inputs[fieldKey].errmsg = errorcheck.msg;
    setInputs({ ...inputs });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errorList = Object.keys(inputs).filter((fieldKey) => {
        const errorcheck = ValidationLibrary.checkValidation(
          inputs[fieldKey].value,
          inputs[fieldKey].validation
        );
        inputs[fieldKey].error = !errorcheck.state;
        inputs[fieldKey].errmsg = errorcheck.msg;
        return !errorcheck.state;
      });
      setInputs({ ...inputs });
      if (!errorList.length) {
        const signUpData = {};
        Object.keys(inputs)?.forEach((key) => {
          if (key !== "confirmPassword") signUpData[key] = inputs[key].value;
        });
        const result = await CLIENT_ACTION.insertUpdateClient({
          params: {
            ...signUpData,
            password: encryptPassword(signUpData.password),
            created_origin: 0,
            status: 0,
          },
          insertUpdateFlag: 0,
        });
        if (result.status === 200 && result.data.status === 1) {
          Toaster.success("Your account has been created successfully");
          navigate("/");
        } else {
          Toaster.error(result.data.message);
        }
      }
    } catch (error) {}
  };

  return (
    <div>
      <div
        className="signup_container align_column"
        style={{ height: "100vh", backgroundColor: "rgb(211 235 255)"}}
      >
        <div className="signup_block align_column" style={{ height: "auto", backgroundColor: "white"}}>
          <div className="logo">
            <img src={"/resources/images/Finlotax.png"} alt="" />
          </div>
          <div className="signup_info">
            <h2 className="welcome">Welcome!</h2>
            <p className="SignUptext">Sign Up</p>
          </div>
          <br/>
          <form action="" className="signup-form">
            <div className="input_block">
              {Object.keys(inputs)?.map((fieldKey, index) => (
                <Labelbox
                  type={inputs[fieldKey].type}
                  labelname={inputs[fieldKey].labelname}
                  value={inputs[fieldKey].value}
                  changeData={(value) => handleChange(value, fieldKey)}
                  error={inputs[fieldKey].error}
                  errmsg={inputs[fieldKey].errmsg}
                  password={inputs[fieldKey].password}
                />
              ))}
            </div>
            <center>
              <button className="signin_button" onClick={handleSubmit}>
                Sign Up
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
