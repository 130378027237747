import { createSlice } from "@reduxjs/toolkit";
import { getAllContracts } from "../action/contractAction";

const initialState = {
  allContracts: [],
};

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllContracts.fulfilled, (state, action) => {
      // Add user to the state array
      state.allContracts = action.payload;
    });
  },
});

export default contractSlice.reducer;
