/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, Labelbox, Button, FrameBox } from "../../components";
import "./client.scss";
import ValidationLibrary from "../../utils/validationfunction";
import Grid from "@material-ui/core/Grid";
import {
  MASTER_ACTION,
  EMPLOYEE_ACTION,
  CLIENT_ACTION,
} from "../../redux/action";
import {
  Toaster,
  dropdownName,
  encryptPassword,
  decryptPassword,
} from "../../utils/common";
import { AddCircleOutlineOutlined, Search } from "@material-ui/icons";
import { masterSetup, header } from "./defaultState";

const CreateClient = () => {
  const defaultCreateState = {
    first_name: {
      labelname: "First Name",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    last_name: {
      labelname: "Last Name",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    email: {
      labelname: "Email Address",
      value: "",
      validation: [{ name: "required" }, { name: "emailNew" }],
      error: null,
      errmsg: null,
    },
    password: {
      labelname: "Password",
      password: true,
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    address_1: {
      labelname: "Address 1",
      value: "",
      error: null,
      errmsg: null,
    },
    address_2: {
      labelname: "Address 2",
      value: "",
      error: null,
      errmsg: null,
    },
    phone: {
      labelname: "Phone",
      value: "",
      // validation: [{ name: "required" }, { name: "mobile" }],
      error: null,
      errmsg: null,
    },
    country_id: {
      labelname: "Country",
      value: null,
      type: "select",
      error: null,
      errmsg: null,
    },
    state_id: {
      labelname: "State",
      type: "select",
      value: null,
      error: null,
      errmsg: null,
    },
    city_id: {
      labelname: "City",
      value: null,
      error: null,
      errmsg: null,
    },
    zipcode: {
      labelname: "Zip Code",
      value: "",
      error: null,
      errmsg: null,
    },

    /////
    defaultAddress: {
      type: "checkbox",
      label: "Set personal address as business address",
      checked: false,
      disabled: true,
      size: 12,
    },
    business_name: {
      labelname: "Business Name",
      value: "",
      disabled: true,
      error: null,
      errmsg: null,
    },
    business_type: {
      labelname: "Business Type",
      type: "select",
      value: "",
      dropdown: [
        { id: "Sole Proprietorship/DBA", value: "Sole Proprietorship/DBA" },
        {
          id: "Limited Liability Company (LLC)",
          value: "Limited Liability Company (LLC)",
        },
        { id: "S Corporation", value: "S Corporation" },
        { id: "C Corporation", value: "C Corporation" },
        { id: "Non Profit Organization", value: "Non Profit Organization" },
      ],
      disabled: true,
      error: null,
      errmsg: null,
    },
    business_address_1: {
      labelname: "Address 1",
      value: "",
      disabled: true,
      error: null,
      errmsg: null,
    },
    business_address_2: {
      labelname: "Address 2",
      value: "",
      disabled: true,
      error: null,
      errmsg: null,
    },
    business_phone: {
      labelname: "Phone",
      value: "",
      disabled: true,
      error: null,
      errmsg: null,
    },
    business_country_id: {
      labelname: "Country",
      value: null,
      type: "select",
      disabled: true,
      error: null,
      errmsg: null,
    },
    business_state_id: {
      labelname: "State Incorporated",
      type: "select",
      value: null,
      disabled: true,
      error: null,
      errmsg: null,
    },
    business_city_id: {
      labelname: "City",
      value: null,
      disabled: true,
      error: null,
      errmsg: null,
    },
    business_zipcode: {
      labelname: "Zip Code",
      value: "",
      disabled: true,
      error: null,
      errmsg: null,
    },
    ////
    client_type: {
      labelname: "Client Type",
      value: "",
      type: "select",
      dropdown: [
        { id: "Individual", value: "Individual" },
        { id: "Business", value: "Business" },
      ],
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    sales: {
      labelname: "Manager",
      value: "",
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    employee_id: {
      labelname: "Employee",
      type: "select",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    branch_office: {
      labelname: "Branch Office",
      type: "select",
      value: "",
      dropdown: [
        { id: "Sunnyvale - USA", value: "Sunnyvale - USA" },
        { id: "Pleasanton - USA", value: "Pleasanton - USA" },
        { id: "Bangalore - India", value: "Bangalore - India" },
      ],
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    source_id: {
      labelname: "Source",
      type: "select",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    do_not_disturb: {
      labelname: "Do not disturb",
      type: "select",
      value: 0,
      dropdown: [
        { id: 0, value: "No" },
        { id: 1, value: "Yes" },
      ],
      error: null,
      errmsg: null,
    },
    status: {
      labelname: "Status",
      type: "select",
      value: 1,
      dropdown: [
        { id: 0, value: "Inactive" },
        { id: 1, value: "Active" },
      ],
      error: null,
      errmsg: null,
    },
  };
  const defaultSearchState = {
    first_name: {
      labelname: "First Name",
      value: "",
      error: null,
      errmsg: null,
    },
    last_name: {
      labelname: "Last Name",
      value: "",
      error: null,
      errmsg: null,
    },
    email: {
      labelname: "Email Address",
      value: "",
      validation: [{ name: "required" }, { name: "emailNew" }],
      error: null,
      errmsg: null,
    },
    phone: {
      labelname: "Phone",
      value: "",
      // validation: [{ name: "required" }, { name: "mobile" }],
      error: null,
      errmsg: null,
    },
    country_id: {
      labelname: "Country",
      value: "",
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    state_id: {
      labelname: "State",
      type: "select",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    city_id: {
      labelname: "City",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    ////
    sales: {
      labelname: "Manager",
      value: "",
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    employee_id: {
      labelname: "Employee",
      type: "select",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    branch_office: {
      labelname: "Branch Office",
      type: "select",
      value: "",
      dropdown: [
        { id: "Sunnyvale - USA", value: "Sunnyvale - USA" },
        { id: "Pleasanton - USA", value: "Pleasanton - USA" },
        { id: "Bangalore - India", value: "Bangalore - India" },
      ],
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    contract_no: {
      labelname: "Contract No.",
      value: "",
      error: null,
      errmsg: null,
    },
    invoice_no: {
      labelname: "Invoice No.",
      value: "",
      error: null,
      errmsg: null,
    },
  };
  const customRef = useRef(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchAddMode, setSearchAddMode] = useState(false);
  const [resetEvent, setResetEvent] = useState(false);

  const [editMode, setEditMode] = useState({
    mode: false,
    client_id: 0,
  });
  const defaultStateSet = (action) => {
    return action ? defaultCreateState : defaultSearchState;
  };
  const [fieldConfiguration, setFieldConfiguration] = useState(
    defaultStateSet()
  );

  /////************ API DATA PROCESS************////////////
  useEffect(() => {
    dispatch(EMPLOYEE_ACTION.getActiveEmployees());
    dispatch(CLIENT_ACTION.getAllClients());
  }, [dispatch]);

  const { activeEmployees } = useSelector((state) => state.employee);
  const { allClients } = useSelector((state) => state.client);
  const masterData = useSelector((state) => state.master);

  const employeeDropdown = () => {
    const employeeData = activeEmployees.map((data) => {
      return {
        id: data.employee_id,
        value: dropdownName(data),
      };
    });
    if (fieldConfiguration.hasOwnProperty("employee_id")) {
      setFieldConfiguration((prevState) => ({
        ...prevState,
        employee_id: {
          ...prevState.employee_id,
          dropdown: employeeData,
        },
        sales: {
          ...prevState.sales,
          dropdown: employeeData,
        },
      }));
    }
  };

  const tableData = useMemo(() => {
    return allClients.map((data) => {
      const {
        client_id,
        first_name,
        last_name,
        email,
        branch_office,
        phone,
        sales_name,
        employee,
        contract_count,
        invoice_count,
        do_not_disturb,
        status,
      } = data;
      return {
        
        first_name,
        last_name,
        email,
        branch_office,
        phone,
        sales_name,
        employee,
        status: status ? "Active" : "Inactive",
        contract_count: (
          <Link
            to="/finlo/invoice_contract"
            state={{
              client_id,
              mode: "contract",
              data,
            }}
            className="link"
          >
            {contract_count}
          </Link>
        ),
        invoice_count: (
          <Link
            to="/finlo/invoice_contract"
            state={{
              client_id,
              mode: "invoice",
              data,
            }}
            className="link"
          >
            {invoice_count}
          </Link>
        ),
        notAvail: do_not_disturb,
        enabledActions: status ? { edit: true, file: true } : { edit: true },
      };
    });
  }, [allClients]);

  const masterDataDropdown = () => {
    const mapKeys = Object.keys(masterSetup);
    mapKeys.forEach((data) => {
      if (fieldConfiguration.hasOwnProperty(masterSetup[data])) {
        setFieldConfiguration((prevState) => ({
          ...prevState,
          [masterSetup[data]]: {
            ...prevState[masterSetup[data]],
            dropdown: masterData[data],
          },
        }));
      }
    });
    employeeDropdown();
  };
  /********* RESPONSE ***********/
  useEffect(() => {
    employeeDropdown();
  }, [activeEmployees]);

  useEffect(() => {
    masterDataDropdown();
  }, [masterData]);

  useMemo(() => {
    // employeeDropdown();
    masterDataDropdown();
  }, [searchAddMode, resetEvent]);

  ////////
  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   */
  function checkValidation(data, key) {

    if (key === "defaultAddress") {
      Object.keys(fieldConfiguration)
        .slice(4, 11)
        .forEach((mapData) => {
          fieldConfiguration[`business_${mapData}`].value = data
            ? fieldConfiguration[mapData].value
            : "";
          if (data && mapData === "country_id") {
            dispatch(
              MASTER_ACTION.getMasterData({
                name: "state",
                name_type: "business_state",
                id: fieldConfiguration[mapData].value,
              })
            );
          }
        });
    }
    if (key === "client_type") {
      Object.keys(fieldConfiguration)
        .slice(11, 21)
        .forEach((mapData) => {
          fieldConfiguration[mapData].validation =
            data === "Business" &&
            ["business_name", "business_type"].includes(
              mapData
            )
              ? [{ name: "required" }]
              : [];
          fieldConfiguration[mapData].disabled =
            data === "Business" ? false : true;

          fieldConfiguration[mapData].value =
            data === "Business" ? fieldConfiguration[mapData].value : null;
        });
      if (data !== "Business") {
        fieldConfiguration.defaultAddress.checked = false;
      }
    }
    if (key === "country_id" || key === "business_country_id") {
      dispatch(
        MASTER_ACTION.getMasterData({
          name: "state",
          name_type: key === "business_country_id" && "business_state",
          id: data,
        })
      );
    }
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      fieldConfiguration[key].validation
    );
    const dynObj = {
      ...fieldConfiguration[key],
      [key === "defaultAddress" ? "checked" : "value"]: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
    };

    setFieldConfiguration((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }

  const modeChange = (action) => {
    setFieldConfiguration(defaultStateSet(action));
    setSearchAddMode(action);
    editMode.mode &&
      setEditMode({
        mode: false,
        client_id: 0,
      });
  };

  const handleCancel = () => {
    dispatch(CLIENT_ACTION.getAllClients());
    setFieldConfiguration(defaultStateSet(searchAddMode));
    setResetEvent(!resetEvent);
    setEditMode({ mode: false, client_id: 0 });
  };

  /**
   *
   */
  async function onSubmit() {
    let targetkeys = [];
    for (let key in fieldConfiguration) {
      if (key !== "defaultAddress") targetkeys.push(key);
    }

    if (!searchAddMode) {
      const params = {};
      targetkeys.forEach(
        (data) => (params[data] = fieldConfiguration[data].value)
      );
      dispatch(CLIENT_ACTION.getSearchClient(params));
      return;
    }

    const mainvalue = {};
    for (const i in targetkeys) {
      const errorcheck = ValidationLibrary.checkValidation(
        fieldConfiguration[targetkeys[i]].value,
        fieldConfiguration[targetkeys[i]].validation
      );
      fieldConfiguration[targetkeys[i]].error = !errorcheck.state;
      fieldConfiguration[targetkeys[i]].errmsg = errorcheck.msg;
      mainvalue[targetkeys[i]] = fieldConfiguration[targetkeys[i]].value;
    }
    const filtererr = targetkeys.filter(
      (obj) => fieldConfiguration[obj].error === true
    );
    if (filtererr.length > 0) {
    } else {
      const params = {};
      if (fieldConfiguration.client_type.value === "Individual") {
        targetkeys.splice(targetkeys.indexOf("business_name"), 9);
      }
      targetkeys.forEach(
        (data) => (params[data] = fieldConfiguration[data].value)
      );

      const response = await CLIENT_ACTION.insertUpdateClient({
        params: {
          ...params,
          password: encryptPassword(params.password),
          created_origin: 1,
        },
        insertUpdateFlag: editMode.mode ? 1 : 0,
        client_id: editMode.client_id,
      });
      if (response.status === 200 && response.data.status === 1) {
        Toaster.success(
          `Client ${!editMode.mode ? "created" : "updated"} successfully`
        );
        handleCancel();
      } else if (response.status === 200 && response.data.status === 0) {
        Toaster.warning(response.data.message);
      } else {
        Toaster.error(
          `Error occurred while ${
            !editMode.mode ? "creating" : "updating"
          } the client`
        );
      }
    }
    setFieldConfiguration((prevState) => ({
      ...prevState,
    }));
  }

  const onAction = async (type, actionData) => {
    const selectedData = allClients.find(
      (data) => data.email === actionData.email
    );
    if (type === "edit") {
      console.log("edit is clicked")
         // onclick on edit smooth scroll
   customRef.current?.scrollIntoView( { behavior: "smooth"})
      setSearchAddMode(true);
      setEditMode({
        mode: true,
        client_id: selectedData.client_id,
        employee: selectedData.employee_id,
      });
      Object.keys(defaultCreateState).forEach((data) => {
        if (data === "password") {
          if (selectedData.created_origin === 2) {
            defaultCreateState[data].disabled = true;
          }
          defaultCreateState[data].value = decryptPassword(selectedData[data]);
        } else if (data === "country_id" || data === "business_country_id") {
          dispatch(
            MASTER_ACTION.getMasterData({
              name: "state",
              name_type: data === "business_country_id" && "business_state",
              id: selectedData[data],
            })
          );
          if (data === "business_country_id") {
            defaultCreateState[data].disabled = false;
          }
          defaultCreateState[data].value = selectedData[data];
        } else if (data === "email") {
          defaultCreateState[data].value = selectedData[data];
          defaultCreateState[data].disabled = false;
        } else if (
          selectedData.client_type === "Business" &&
          data.includes("business")
        ) {
          defaultCreateState[data].disabled = false;
          defaultCreateState[data].value = selectedData[data];
        } else {
          defaultCreateState[data].value = selectedData[data];
        }
      });
      setFieldConfiguration(defaultCreateState);
    } else if (type === "file") {
      navigate("/finlo/documents", {
        state: {
          clientDetails: {
            client_id: selectedData.client_id,
            home_folder: selectedData?.s3_root_folder,
          },
        },
      });
    }
  };
  return (
    <Grid container alignItems="center" spacing={2} ref={customRef}>
      <Grid item xs={12}>
        <Grid container spacing={2} className="paddingTop">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="btn-wrapper-left"
          >
            <div className="TitlePane">
              {searchAddMode ? "Create Client" : "Search Client"}
            </div>
            <Button
              iconCls="btn_icon"
              icon={!searchAddMode ? <AddCircleOutlineOutlined /> : <Search />}
              text={!searchAddMode ? "Create Client" : "Search Client"}
              handleClick={() => modeChange(!searchAddMode ? true : false)}
            />
          </Grid>
        </Grid>
      </Grid>
      {searchAddMode ? (
        <>
          <Grid container spacing={3} className="paddingTop">
            <Grid item xs={12} sm={6} md={6} lg={6} direction="row">
              <FrameBox title="Personal Details" variant="small">
                <Grid container alignItems="center" spacing={2}>
                  {Object.keys(fieldConfiguration)
                    .slice(0, 11)
                    .map((data, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          lg={6}
                          key={`data${index}`}
                          direction="row"
                        >
                          <Labelbox
                            type={fieldConfiguration[data].type || "text"}
                            labelname={ <span> {fieldConfiguration[data].labelname} 
                            {index <4 && <span style={{color:'red'}}>*</span>  } 
                             
                             </span>}
                            password={fieldConfiguration[data]?.password}
                            dropdown={
                              fieldConfiguration[data]?.type === "select" &&
                              fieldConfiguration[data]?.dropdown
                                ? fieldConfiguration[data]?.dropdown
                                : false
                            }
                            changeData={(e) => checkValidation(e, data)}
                            disabled={fieldConfiguration[data].disabled}
                            // CONDITION FOR THE FIRST AND LAST NAME FIRST LETTER CAPS
                            value={ data == "first_name" || data == "last_name" ? fieldConfiguration[data].value.charAt(0).toUpperCase() + fieldConfiguration[data].value.slice(1).toLowerCase() : fieldConfiguration[data].value }
                            error={fieldConfiguration[data].error}
                            errmsg={fieldConfiguration[data].errmsg}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </FrameBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} className="support-info">
              <FrameBox title="Business Details" variant="small">
                <Grid container alignItems="center" spacing={2}>
                  {Object.keys(fieldConfiguration)
                    .slice(11, 21)
                    .map((data, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={
                            data === "defaultAddress"
                              ? fieldConfiguration[data].size
                              : 6
                          }
                          sm={12}
                          lg={
                            data === "defaultAddress"
                              ? fieldConfiguration[data].size
                              : 6
                          }
                          key={`data${index}`}
                        >
                          <Labelbox
                            checked={fieldConfiguration[data].checked}
                            label={fieldConfiguration[data].label}
                            type={fieldConfiguration[data].type || "text"}
                            labelname={ <span> {fieldConfiguration[data].labelname} 
                            {index <3 && <span style={{color:'red'}}>*</span>  } 
                             
                             </span>}                            password={fieldConfiguration[data]?.password}
                            dropdown={
                              fieldConfiguration[data]?.type === "select" &&
                              fieldConfiguration[data]?.dropdown
                                ? fieldConfiguration[data]?.dropdown
                                : false
                            }
                            changeData={(e) => checkValidation(e, data)}
                            disabled={fieldConfiguration[data].disabled}
                            value={fieldConfiguration[data].value}
                            error={fieldConfiguration[data].error}
                            errmsg={fieldConfiguration[data].errmsg}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </FrameBox>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="paddingTop">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FrameBox title=" FinloTax Details" variant="small">
                <Grid container alignItems="center" spacing={2}>
                  {Object.keys(fieldConfiguration)
                    .slice(-7)
                    .map((data, index) => {
                      if (
                        (!editMode.mode &&
                          data !== "status" &&
                          data !== "do_not_disturb") ||
                        editMode.mode
                      ) {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sm={6}
                            lg={3}
                            key={`data${index}`}
                          >
                            <Labelbox
                              type={fieldConfiguration[data].type || "text"}
                          

                              labelname={ <span> {fieldConfiguration[data].labelname} 
                        <span style={{color:'red'}}>*</span> 
                               
                               </span>}
                              password={fieldConfiguration[data]?.password}
                              dropdown={
                                fieldConfiguration[data]?.type === "select" &&
                                fieldConfiguration[data]?.dropdown
                                  ? fieldConfiguration[data]?.dropdown
                                  : false
                              }
                              changeData={(e) => checkValidation(e, data)}
                              disabled={fieldConfiguration[data].disabled}
                              value={fieldConfiguration[data].value}
                              error={fieldConfiguration[data].error}
                              errmsg={fieldConfiguration[data].errmsg}
                            />
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </FrameBox>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container spacing={3} className="paddingTop">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FrameBox title="Search Fields" variant="small">
              <Grid container alignItems="center" spacing={2}>
                {Object.keys(fieldConfiguration).map((data, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sm={6}
                      lg={3}
                      key={`data${index}`}
                    >
                      <Labelbox
                        type={fieldConfiguration[data].type || "text"}
                        labelname={fieldConfiguration[data].labelname}
                        password={fieldConfiguration[data]?.password}
                        dropdown={
                          fieldConfiguration[data]?.type === "select" &&
                          fieldConfiguration[data]?.dropdown
                            ? fieldConfiguration[data]?.dropdown
                            : false
                        }
                        changeData={(e) => checkValidation(e, data)}
                        disabled={fieldConfiguration[data].disabled}
                        value={fieldConfiguration[data].value}
                        error={fieldConfiguration[data].error}
                        errmsg={fieldConfiguration[data].errmsg}
                        onKeyEnter={onSubmit}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </FrameBox>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <div className="hbox">
          <Button
            text={`${
              editMode.mode ? "Update" : searchAddMode ? "Submit" : "Search"
            }`}
            large
            handleClick={onSubmit}
          />
          <Button text="Reset" large handleClick={handleCancel} />
        </div>
      </Grid>

      {/* -----------  CLIENTS TABLE      ------------------- */}
      <Grid item xs={12} className="paddingTop">
        <Table
          title="Clients"
          header={header}
          data={tableData}
          EnableSno={true}
          actions={["edit","file"]}
          onActionClick={onAction}
          icons={{ notAvail: true }}
        //  restrictActionIcon
        />
      </Grid>
    </Grid>
  );
};
export default CreateClient;
