const masterSetup = {
  state: "state_id",
  business_state: "business_state_id",
  source: "source_id",
  country: "country_id",
  business_country: "business_country_id",
};

const header = [
  { id: "notAvail", label: "" },

  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "email", label: "Email" },
  { id: "branchOffice", label: "Branch Office" },
  { id: "phone", label: "Phone" },
  { id: "sales", label: "Manager" },
  { id: "employee", label: "Employee" },
  { id: "status", label: "Status" },
  { id: "contract", label: "Contract" },
  { id: "invoice", label: "Invoice" },
  { id: "action", label: "Action" },
];

export { masterSetup, header };
