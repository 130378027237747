import { createSlice } from "@reduxjs/toolkit";
import { getAllTimesheets } from "../action/timesheetAction";

const initialState = {
  allTimesheets: [],
};

export const timesheetSlice = createSlice({
  name: "timesheet",
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllTimesheets.fulfilled, (state, action) => {
      // Add user to the state array
      state.allTimesheets = action.payload;
    });
  },
});

export default timesheetSlice.reducer;
