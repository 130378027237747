import React, { useContext } from "react";
import { MenuItem, Avatar, Paper, Menu , List,ListItem} from "@material-ui/core";
import {
  ManagePack,
  Notepad,
  ClientNav,
  Employees,
  InvoiceNav,
} from "../../images";
import "./notify-page.scss";
import {
  CONTRACT_ACTION,
  DOCUMENT_ACTION,
  INVOICE_ACTION,
  MASTER_ACTION,
} from "../../redux/action";
import { Toaster } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { dateTimeFormatter, NotificationContext } from "../../utils/common";
const AllNotifications = (props) => {
  const { notifications } = useContext(NotificationContext);
  console.log("this is notification", notifications)
  const USER_TYPE = localStorage.getItem("user_role");
  const navigate = useNavigate();
  const [anchor, setAnchor] = React.useState(null);


  const showAvatar = (type) => {
    switch (type) {
      case "client":
        return ClientNav;
      case "employee":
        return Employees;
      case "contract":
        return Notepad;
      case "invoice":
        return InvoiceNav;
      case "payment":
        return ManagePack;
      default:
        return;
    }
  };

  const onUpdateNotification = async (notify_data) => {
    console.log("OnupdateNotification method is triggred", notify_data);
    await MASTER_ACTION.updateNotification(notify_data.notification_id);
    const data = await MASTER_ACTION.getNotification();
  //  setNotifications(data);

    const ACTION = {
      folder: [DOCUMENT_ACTION.getFolderDetails, "/finlo/documents"],
      document: [DOCUMENT_ACTION.getDocumentDetails, "/finlo/documents"],
      invoice: [
        INVOICE_ACTION.getInvoiceDetail,
        `${
          USER_TYPE === "client" ? "/finlo/payment" : "/finlo/invoice_contract"
        }`,
      ],
      contract: [
        CONTRACT_ACTION.getContractDetail,
        `${
          USER_TYPE === "client"
            ? "/finlo/contract_proposal"
            : "/finlo/invoice_contract"
        }`,
      ],
    };
    const { notification_type, notification_item_id } = notify_data;

    const response = await ACTION[notification_type][0](notification_item_id);
    if (response.status === 200) {
      navigate(ACTION[notification_type][1], {
        state: ["folder", "document"].includes(notification_type)
          ? { clientDetails: response.data.data }
          : {
              client_id: response.data.data.billed_to,
              mode: notification_type,
              data: response.data.data,
              view: true,
            },
      });
    } else {
      Toaster.error("Error occurred while direction");
    }
  };

  return (
    <Paper className="notify-all-paper">
    <div className="notify-all-label">Notifications</div>
    <List>
      {notifications && notifications.length > 0 ? (
        notifications.map((option, index) => (
          <ListItem
            button
            key={index}
            onClick={() => onUpdateNotification(option)}
            className={`${
              option?.view_status === "Not Viewed" ? "notify-unread-menuitem" : ""
            } notify-border`}
          >
            <div className="notify-tile">
              <Avatar
                alt={option.notification_type}
                src={showAvatar(option.notification_type)}
                className="notify-avatar"
              />
              <div className="message-tile">
                <div className="notify-message notify-all-message">
                  {option.message}
                </div>
                <div className="notify-time">
                  {dateTimeFormatter(option.createdAt)}
                </div>
              </div>
            </div>
          </ListItem>
        ))
      ) : (
        <ListItem>
          <div className="notify-tile">
            <div className="notify-message notify-all-message">
              No notifications available.
            </div>
          </div>
        </ListItem>
      )}
    </List>
  </Paper>
  );
};

export default AllNotifications;
