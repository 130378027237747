import React from "react";
import { Folder, File } from "../../images";
// import Menus from "../menus";
import "./file-folder.scss";
import { Visibility, GetAppRounded, Delete } from "@material-ui/icons";
import { colors } from "@material-ui/core";

const FileFolderCard = (props) => {
  const {
    name,
    // options,
    onMenuClick,
    onClick,
    details,
    modifiedAt,
    document_type,
    document_extension,
    type,
    size,
    id,
  } = props;

  return (
    <div className="folder-table">
      <div className="card" onClick={() => onClick && onClick(name, id)}>
        <div className="folder-cell" id="doc_name">
          <div style={{ display: "inline-flex" }}>
            <span className="folder_icon">
              <img src={type === "Document" ? File : Folder} alt="folder" />
            </span>
            <div>{name}</div>
          </div>
        </div>
        <div className="folder-cell" id="doc_date">
          {modifiedAt || "---"}
        </div>

        <div className="folder-cell" id="doc_type">
          {type === "Document" ? document_extension : type || "---"}
        </div>
        <div className="folder-cell" id="doc_size">
          {type === "Document" ? size : ""}
        </div>
        <div className="folder-cell">
          <div className="menu_icon">
            {type === "Document" &&
              new RegExp(["image", "application/pdf"].join("|")).test(
                document_type
              ) && (
                <div
                  className="flex flex-center" onClick={() => {
                    new RegExp(["image", "application/pdf"].join("|")).test(
                      document_type
                    ) && onMenuClick && onMenuClick("Preview", details);
                  }}
                  // onClick={() =>
                  //   onMenuClick ? onMenuClick("Preview", details) : {}
                  // }
                >
                  <Visibility />
                </div>
              )}
            <div
              className="flex flex-center"
              onClick={(e) => {
                e.stopPropagation();
                onMenuClick && onMenuClick("Download", details);
              }}
            >
              <GetAppRounded />
            </div>
            {type === "Document" &&
              new RegExp(["image", "application/pdf",  "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].join("|")).test(
                document_type
              ) && localStorage.getItem("user_role") !=="client" && (
            <div className="flex flex-center" style={{ color: '#e41f3a' }} onClick={(e) => {
             
           onMenuClick("Delete", details); }}>
                    <Delete />
                    </div> )}
          </div>
          {/* <Menus
            options={options}
            onMenuClick={(item) =>
              onMenuClick ? onMenuClick(item, details) : {}
            }
          /> */}
        </div>
      </div>
    </div>
  );
};

export default FileFolderCard;
