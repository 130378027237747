import React from "react";
import { Grid, Paper } from "@material-ui/core";
import {
  IndividualService,
  Business,
  TaxCalculator,
  Compliance,
  Payroll,
  InvoiceNav,
} from "../../images";
import "./service_offers.scss";

const ServiceOffers = () => {
  const offers = [
    {
      offer: "Tax Planning & Consulation",
      desc: "Tax planning and consultation service with our EA and experienced tax professionals resolve various tax challenges faced by businesses and individuals.",
      icon: TaxCalculator,
    },
    {
      offer: "Individual Tax Services",
      desc: "FinloTax is the right choice for individuals seeking professional addice on tax planning and IT returns. Our tax and financial planning service are reliable and simple.",
      icon: IndividualService,
    },
    {
      offer: "Business Tax Services",
      desc: "The team of professionals at FinloTax can handle all your business needs such as Federal & State tax filling, franchise tax filling, R&D tax credit, and sales tax filling efficiently, saving your time and effort.",
      icon: InvoiceNav,
    },
    {
      offer: "Business Formation Services",
      desc: "If you are looking for the right guidance on business formation or incorporation, FinloTax can set up your business in a cost-effective manner with perfect structure",
      icon: Business,
    },
    {
      offer: "Bookkeeping and Payroll Services",
      desc: "No matter what size your business is or what you do, bookkeeping and payroll services are legal requirements. We can keep it up to date for you and lessen your burden woth our high-skilled accountants.",
      icon: Payroll,
    },
    {
      offer: "Misc. Tax and Compliance Services",
      desc: "We are dedicated in providing the highest standard of tax compliance services by focusing on a broader strategic version and increasing your overall tax performance.",
      icon: Compliance,
    },
  ];
  return (
    <Grid container spacing={4} style={{ padding: "2rem 1rem" }}>
      {offers.map((item) => (
        <Grid item xs={12} sm={6} lg={4} md={4}>
          <Paper className="offer-paper">
            <div className="offer-card">
              <div className="offer-title">{item.offer}</div>
              <div className="offer-desc">{item.desc}</div>
              <div className="offer-icon">
                <img src={item.icon} />
              </div>
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default ServiceOffers;
