import { createSlice } from "@reduxjs/toolkit";
import { getMasterData, getSubActivityDetails } from "../action/masterAction";

const initialState = {
  city: [],
  source: [],
  state: [],
  role: [],
  country: [],
  payment_mode: [],
  business_country: [],
  business_state: [],
  getSubActivityDetails: [],
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getSubActivityDetails.fulfilled, (state, action) => {
      // Add user to the state array
      state.getSubActivityDetails = action.payload;
    });
    builder.addCase(getMasterData.fulfilled, (state, action) => {
      if (action.meta.arg.name_type)
        state[action.meta.arg.name_type] = action.payload;
      else {
        state[action.meta.arg.name] = action.payload;
        if (action.meta.arg.name === "country")
          state.business_country = action.payload;
        if (action.meta.arg.name === "role")
          localStorage.setItem("role_data", JSON.stringify(action.payload));
      }
    });
  },
});

export default clientSlice.reducer;
