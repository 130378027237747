/* eslint-disable no-undef */
import React, { useState, useLayoutEffect, useMemo, useRef } from "react";
import { Labelbox, FrameBox, Table, Button, Modal } from "../../components";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import "./timesheet.scss";
import {
  TIMESHEET_ACTION,
  EMPLOYEE_ACTION,
  MASTER_ACTION,
} from "../../redux/action";
import {
  dateFormatter,
  dateTimeFormatter,
  dropdownName,
  Toaster,
} from "../../utils/common";
import { checkValidation, onSubmitValidation } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import TimeSheetView from "../../components/timesheetView";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [viewMode, setViewMode] = useState({ state: false, data: {} });

  const [subActivityState, setSubActivityState] = useState({});

  const defaultValues = {
    start_date: {
      // value: dateFormatter(false, true),
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    end_date: {
      // value: dateFormatter(false, true),
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    employee_id: {
      value:
        localStorage.getItem("user_role") !== "employee"
          ? ""
          : localStorage.getItem("user_id"),
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    rejected_comments: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
  };
  const header = [
    { id: "start_date", label: "Start Date" },
    { id: "end_date", label: "End Date" },
    { id: "no_of_hours", label: "Total hours" },
    { id: "status", label: "Status" },
    { id: "updated", label: "Last Updated" },
    { id: "action", label: "Action" },
  ];
  const header1 = [
    { id: "start_date", label: "Start Date" },
    { id: "end_date", label: "End Date" },
    { id: "employee_id", label: "Employee" },
    { id: "no_of_hours", label: "Total hours" },
    { id: "status", label: "Status" },
    { id: "updated", label: "Last Updated" },
    { id: "action", label: "Action" },
  ];
  const [fieldConfiguration, setFieldConfiguration] =
    React.useState(defaultValues);
  const [openModal, setOpenModal] = useState(false);
  const rejectedEmployeeId = useRef();

  useLayoutEffect(() => {
    dispatch(EMPLOYEE_ACTION.getActiveEmployees());
    dispatch(TIMESHEET_ACTION.getAllTimesheets());
  }, []);

  const {
    timesheet: { allTimesheets },
    employee: { activeEmployees },
  } = useSelector((state) => state);

  const timesheetTableData = useMemo(() => {
    const RESPONSE = [...allTimesheets];
    const sortedData = RESPONSE.sort((a, b) =>
      a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
    );
    return sortedData.map((data) => {
      const {
        start_date,
        end_date,
        employee_name,
        total_hours,
        status,
        timesheet_id,
        updatedAt,
      } = data;
      return {
        start_date: dateFormatter(start_date),
        end_date: dateFormatter(end_date),
        employee_name,
        total_hours,
        status:
          status === 0
            ? "Saved"
            : status === 1
            ? "Pending"
            : status === 2
            ? "Approved"
            : "Rejected",
        updatedAt: dateTimeFormatter(updatedAt),
        timesheet_id,
      };
    });
  }, [allTimesheets]);

  const employeesData = useMemo(() => {
    return activeEmployees.map((data) => {
      const { employee_id } = data;
      return {
        id: employee_id,
        value: dropdownName(data),
      };
    });
  }, [activeEmployees]);

  function handleCancel() {
    setFieldConfiguration(defaultValues);
    dispatch(TIMESHEET_ACTION.getAllTimesheets());
  }

  const onAction = async (action, actionData, actionIndex) => {
    if (action.text === "Approve") {
      const signal = await TIMESHEET_ACTION.approveTimesheet(
        actionData.timesheet_id
      );
      if (signal.status) {
        Toaster.success(signal.message);
        dispatch(TIMESHEET_ACTION.getAllTimesheets());
      } else
        Toaster.error(signal.message || "Timesheet not approved successfully");
    } else if (action.text === "Reject") {
      setOpenModal(true);
      rejectedEmployeeId.current = actionData.timesheet_id;
    } else if (action.text === "View") {
      const timesheetEntries = await TIMESHEET_ACTION.getTimesheetEntries({
        timesheet_id: actionData.timesheet_id,
      });
      const DATA = timesheetEntries[0];
      setViewMode({ state: true, data: DATA });
    } else if (action.text === "Edit") {
      navigate("/finlo/create_timesheet", {
        state: actionData,
      });
    }
  };

  const onChange = (value, key) => {
    checkValidation({
      key,
      value,
      state: fieldConfiguration,
      setState: setFieldConfiguration,
    });
  };

  const onSearch = () => {
    dispatch(
      TIMESHEET_ACTION.getAllTimesheets({
        start_date: fieldConfiguration.start_date.value,
        end_date: fieldConfiguration.end_date.value,
        employee_id: fieldConfiguration.employee_id.value,
      })
    );
  };

  const onRejectSubmit = async () => {
    const validate = onSubmitValidation({
      state: fieldConfiguration,
      setState: setFieldConfiguration,
      checkFields: ["rejected_comments"],
    });
    if (!validate.length) {
      const signal = await TIMESHEET_ACTION.rejectTimesheet({
        timesheet_id: rejectedEmployeeId.current,
        comments: fieldConfiguration.rejected_comments.value,
      });
      if (signal.status) {
        dispatch(TIMESHEET_ACTION.getAllTimesheets());
        Toaster.success(signal.message);
      } else Toaster.error(signal.message || "Timesheet has not rejected");
      rejectedEmployeeId.current = null;
      fieldConfiguration.rejected_comments.value = "";
      setOpenModal(false);
    }
  };

  // SubActivity Component
  const SubActivity = () => {
    const {
      master: { activity, getSubActivityDetails },
    } = useSelector((state) => state);

    const onChange = (value, key) => {
      checkValidation({
        key,
        value,
        state: activityData,
        setState: setActivityData,
      });
    };

    const defaultValuesActivity = {
      activity_id: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      sub_activity: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
    };
    const [activityData, setActivityData] = useState(defaultValuesActivity);

    const onSubActivitySubmit = async () => {
      const validate = onSubmitValidation({
        state: activityData,
        setState: setActivityData,
        checkFields: ["activity_id", "sub_activity"],
      });
      if (!validate.length) {
        const signal = await MASTER_ACTION.insertSubActivity({
          activity_id: activityData.activity_id.value,
          sub_activity: activityData.sub_activity.value,
        });
        if (signal.status) {
          dispatch(MASTER_ACTION.getSubActivityDetails());
          Toaster.success(signal.message);
          setActivityData((prev) => ({ ...prev, sub_activity: "" }));
        } else Toaster.error(signal.message || "Sub Activity not got inserted");
      }
    };

    const onAction = async (type, actionData, actionIndex) => {
      if (type === "delete") {
        onDeleteSubActivity(actionData.sub_activity_id);
      }
    };
    const onDeleteSubActivity = async (sub_activity_id) => {
      const signal = await MASTER_ACTION.deleteSubActivity(sub_activity_id);
      if (signal.status) {
        dispatch(MASTER_ACTION.getSubActivityDetails());
        Toaster.success(signal.message);
      } else Toaster.error(signal.message || "Sub Activity not deleted");
    };
    const subActivityTable = useMemo(() => {
      return getSubActivityDetails.map(
        ({ activity, sub_activity, updatedAt, sub_activity_id }, index) => {
          return {
            s_no: index + 1,
            activity: activity,
            sub_activity: sub_activity,
            updatedAt: dateTimeFormatter(updatedAt),
            enabledActions: { delete: true },
            sub_activity_id: sub_activity_id,
          };
        }
      );
    }, [getSubActivityDetails]);

    return (
      <Grid container alignItems="center" spacing={2} className="grid-padding">
        <FrameBox
          title="Sub Activity"
          variant="small"
          className="full_width_div"
        >
          <Grid container xs={12} sm={12} md={12} lg={12} spacing={3}>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <Labelbox
                type="select"
                labelname="Activity"
                dropdown={activity}
                changeData={(e) => onChange(e, "activity_id")}
                value={activityData.activity_id.value}
                error={activityData.activity_id.error}
                errmsg={activityData.activity_id.errmsg}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <Labelbox
                type="text"
                labelname="Sub Activity"
                changeData={(e) => onChange(e, "sub_activity")}
                value={activityData.sub_activity.value}
                error={activityData.sub_activity.error}
                errmsg={activityData.sub_activity.errmsg}
              />
            </Grid>
          </Grid>
        </FrameBox>
        <Grid item xs={12}>
          <div className="hbox">
            <Button text="Submit" large handleClick={onSubActivitySubmit} />
            {/* <Button text="Reset" large handleClick={handleCancel} /> */}
          </div>
        </Grid>
        <Grid container spacing={3} className="paddingTop">
          <Grid item xs={12}>
            <Table
              variant="small"
              title="Timesheet"
              header={[
                { id: "S_No", label: "S.No" },
                { id: "activity", label: "Activity" },
                { id: "sub_activity", label: "Sub Activity" },
                { id: "updated", label: "Last Updated" },
                { id: "action", label: "Action" },
              ]}
              data={subActivityTable}
              actions={["delete"]}
              onActionClick={onAction}
              hiddenFields={["sub_activity_id"]}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container alignItems="center" spacing={2} className="grid-padding">
      <Grid item xs={12}>
        <Grid container spacing={2} className="paddingTop">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="btn-wrapper-left"
          >
            <div className="TitlePane">Timesheet</div>
            {localStorage.getItem("user_role") !== "admin" && (
              <Button
                iconCls="btn_icon"
                icon={<AddCircleOutlineOutlined />}
                text="Add Timesheet"
                handleClick={() =>
                  { 
                    console.log("the user role",localStorage.getItem("user_role"))
                    
                    navigate("/finlo/create_timesheet")}}
              />
            )}
            {/* Changed sub activity visibility to admin  */}
            {localStorage.getItem("user_role") == "admin" && (
              <Button
                iconCls="btn_icon"
                icon={<AddCircleOutlineOutlined />}
                text="Add Sub Activity"
                handleClick={() => {
                  console.log("the user role",localStorage.getItem("user_role"))
                  setSubActivityState((prev) => ({ ...prev, open: true }))
                }
               
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <FrameBox
        title="Search Timesheet"
        variant="small"
        className="full_width_div"
      >
        <Grid container xs={12} sm={12} md={12} lg={12} spacing={3}>
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Labelbox
              type="datepicker"
              labelname="Start Date"
              changeData={(e) => onChange(e, "start_date")}
              value={fieldConfiguration.start_date.value}
              error={fieldConfiguration.start_date.error}
              errmsg={fieldConfiguration.start_date.errmsg}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Labelbox
              type="datepicker"
              labelname="End Date"
              changeData={(e) => onChange(e, "end_date")}
              value={fieldConfiguration.end_date.value}
              error={fieldConfiguration.end_date.error}
              errmsg={fieldConfiguration.end_date.errmsg}
            />
          </Grid>
          {localStorage.getItem("user_role") !== "employee" && (
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <Labelbox
                type="select"
                labelname="Employee"
                changeData={(e) => onChange(e, "employee_id")}
                value={fieldConfiguration.employee_id.value}
                dropdown={employeesData}
                error={fieldConfiguration.employee_id.error}
                errmsg={fieldConfiguration.employee_id.errmsg}
              />
            </Grid>
          )}
        </Grid>
      </FrameBox>

      <Grid item xs={12}>
        <div className="hbox">
          <Button text="Search" large handleClick={onSearch} />
          <Button text="Reset" large handleClick={handleCancel} />
        </div>
      </Grid>
      <Grid container spacing={3} className="paddingTop">
        <Grid item xs={12}>
          <Table
            variant="small"
            title="Timesheet"
            header={
              ["admin", "manager"].includes(localStorage.getItem("user_role"))
                ? header1
                : header
            }
            data={timesheetTableData}
            actions={[
              {
                type: "Button",
                text: "Approve",
                ui: "success",
                hide: !["admin", "manager"].includes(
                  localStorage.getItem("user_role")
                ),
                showPrivilege: { key: "status", value: ["Pending"] },
              },
              {
                type: "Button",
                text: "Reject",
                ui: "danger",
                hide: !["admin", "manager"].includes(
                  localStorage.getItem("user_role")
                ),
                showPrivilege: { key: "status", value: ["Pending"] },
              },
              {
                type: "Button",
                text: "View",
                ui: "primary",
              },
              {
                type: "Button",
                text: "Edit",
                ui: "primary",
                // hide: !["admin", "manager"].includes(
                //   localStorage.getItem("user_role")
                // ),
                showPrivilege: { key: "status", value: ["Rejected", "Saved"] },
              },
            ]}
            onActionClick={onAction}
            hiddenFields={
              !["admin", "manager"].includes(localStorage.getItem("user_role"))
                ? ["timesheet_id", "employee_name"]
                : ["timesheet_id"]
            }
            // restrictStatusButton
          />
        </Grid>
      </Grid>
      <Modal
        title="Rejection Reason"
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          fieldConfiguration.rejected_comments.value = "";
        }}
      >
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          spacing={3}
          style={{ margin: 0 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Labelbox
              type="textarea"
              labelname="Comments"
              changeData={(e) => onChange(e, "rejected_comments")}
              value={fieldConfiguration.rejected_comments.value}
              error={fieldConfiguration.rejected_comments.error}
              errmsg={fieldConfiguration.rejected_comments.errmsg}
              rows={3}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="flex-center flex"
          >
            <Button
              text="Submit"
              handleClick={onRejectSubmit}
              handleClose={() => {
                rejectedEmployeeId.current = null;
                setOpenModal(false);
              }}
            />
          </Grid>
        </Grid>
      </Modal>
      <Modal
        title="Sub Activity"
        maxWidth
        open={subActivityState.open}
        handleClose={() => {
          setSubActivityState({ open: false });
        }}
      >
        <SubActivity />
      </Modal>
      {viewMode.state && viewMode.data instanceof Object && (
        <Modal
          title="Timesheet View"
          maxWidth
          open={viewMode.state}
          handleClose={() => {
            setViewMode({ state: false, date: [] });
          }}
        >
          <TimeSheetView data={viewMode.data} />
        </Modal>
      )}
    </Grid>
  );
};

export default Dashboard;
