import React from "react";
import { FrameBox, InfoTable } from "../../components";
import { Grid, Paper } from "@material-ui/core";

const Profile = () => {
  const USER_DATA = JSON.parse(localStorage.getItem("USER_DATA"));
  console.log("the user datas of the profile page are", USER_DATA)

  const profileDetails = [
    {
      title: "Personal",
      heading: [
       "Tax Payer's Name",
        "Email ID",
        "Phone",
        "Country",
        "State",
        "City",
        "Zip Code",
      ],
      info: {
        name: USER_DATA.first_name + " " + USER_DATA.last_name,
        email: USER_DATA.email,
        phone: USER_DATA.phone,
        country: USER_DATA.country,
        state: USER_DATA.state,
        city: USER_DATA.city_id,
        zip: USER_DATA.zipcode,
      },
    },
    {
      title: "Business",
      heading: [
        "Business Name",
        "Type",
        "Phone",
        "Country",
        "State Incorporated",
        "City",
        "Zip Code",
      ],
      info: {
        name: USER_DATA.business_name,
        type: USER_DATA.business_type,
        phone: USER_DATA.business_phone,
        country: USER_DATA.business_country,
        state: USER_DATA.business_state,
        city: USER_DATA.business_city_id,
        zip: USER_DATA.business_zipcode,
      },
    },
  ];
  return (
    <FrameBox title="Profile">
      <Grid container spacing={3} className="paddingTop">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FrameBox title="Tax Associate Info" variant="small">
            <Paper>
              <InfoTable
                titles={["Name", "Email ID", "Phone"]}
                info={{
                  name: USER_DATA.employee || "Not Assigned",
                  email: USER_DATA.employee_email || "Not Assigned",
                  phone: USER_DATA.employee_phone || "",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="support-info">
          <FrameBox title="Support Info" variant="small">
            <Paper>
              <InfoTable
                titles={["Email ID", "Phone"]}
                info={{
                  email: "help@finlotax.com",
                  contact: "(408) 822-9406",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        {profileDetails.map((data) => (
          <Grid item xs={12} sm={12} md={6} lg={6} className="support-info">
            <FrameBox title={data.title} variant="small">
              <Paper>
                <InfoTable transparent titles={data.heading} info={data.info} />
              </Paper>
            </FrameBox>
          </Grid>
        ))}
      </Grid>
    </FrameBox>
  );
};

export default Profile;
