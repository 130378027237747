import React, { useState, useEffect, useMemo } from "react";
import {
  FileFolderCard,
  Labelbox,
  BreadCrumbs,
  Button,
  Modal,
  FrameBox,
} from "../../components";
import{Slider, Switch, FormControlLabel} from '@mui/material';
import { useLocation } from "react-router-dom";
import { AddFolder, FileUpload } from "../../images";
import "./document.scss";
import { Toaster, dateTimeFormatter } from "../../utils/common";
import { useSelector, useDispatch } from "react-redux";
import { DOCUMENT_ACTION, CLIENT_ACTION } from "../../redux/action";
import Empty from "antd/es/empty";

const UploadDoc = () => {
  const location = useLocation();
  const [sendNotifications, setSendNotifications] = useState(false);
  const [notificationLevel] = useState(50);
 
 
  const { userFiles, userFolders } = useSelector((state) => state.document);
  const clientData = useSelector((state) => state.client);

  const clientDetails = location.state?.clientDetails;

  const dispatch = useDispatch();

  const getHomeFolderAndUserID = (type) => {
    // eslint-disable-next-line no-unused-expressions
    if (type === "id") {
      // eslint-disable-next-line no-unused-expressions
      return clientDetails?.client_id
        ? clientDetails?.client_id
        : localStorage.getItem("user_role") === "client" &&
            localStorage.getItem("user_id");
    }
    return clientDetails?.home_folder
      ? clientDetails?.home_folder
      : localStorage.getItem("user_role") === "client" &&
          localStorage.getItem("user_root_folder");
  };

  const [HOME_USER_ID, setHOME_USER_ID] = useState(
    getHomeFolderAndUserID("id")
  );

  const getNavPath = () => {
    // eslint-disable-next-line no-unused-expressions
    return clientDetails?.directory_details
      ? clientDetails?.client_document_id
        ? JSON.parse(clientDetails?.directory_details)
        : [
            ...JSON.parse(clientDetails?.directory_details),
            {
              id: clientDetails.client_folder_id,
              name: clientDetails.folder_name,
            },
          ]
      : [{ id: 0, name: "Home", created_by: 0 }];
  };

  const [HOME_FOLDER, setHOME_FOLDER] = useState(getHomeFolderAndUserID());

  const [navPath, setNavPath] = useState(getNavPath());
  const [isModalOpen, setModalOpen] = useState(false);
  const [docInfo, setDocInfo] = useState({ RAW_DATA: "", document_type: "" });
  const [newFolderName, setNewFolderName] = useState("");
  const [fileData, setFileData] = useState([]);
  const [fileStructure, setFileStructure] = useState({
    folders: [],
    files: [],
  });

  useMemo(() => {
    setNavPath(getNavPath());
  }, [clientDetails]);

  const getPath = () => {
    let path = navPath[navPath.length - 1];
    if (path.name === "Home") {
      path = { id: 0, name: HOME_FOLDER };
    }
    return path;
  };
  const getKeyPath = () => {
    let keyPathArr = [...navPath];
    keyPathArr.splice(0, 1, { id: 0, name: HOME_FOLDER });
    const keyPath = keyPathArr.map((u) => u.name).join("/");
    return keyPath;
  };

  const getFoldersFiles = () => {
    console.log("HOME USER ID: ",HOME_USER_ID);
    let path = getPath();
    dispatch(
      DOCUMENT_ACTION.getUserFolders({
        user_id: HOME_USER_ID,
        folder_name: path.name,
        folder_id: path.id,
      })
    );
    dispatch(
      DOCUMENT_ACTION.getUserFiles({
        user_id: HOME_USER_ID,
        folder_name: path.name,
        folder_id: path.id,
      })
    );
  };

  const clientsList = useMemo(() => {
    return (
      localStorage.getItem("user_role") !== "client" &&
      clientData?.activeClients &&
      clientData.activeClients.map((data, index) => {
       // console.log("Client Data:",data);
        return {
          id: data.client_id,
          value: `${data.first_name} ${data.last_name} - ${data.email}`,
        };
      })
    );
  }, [clientData]);

  const chooseClient = async (e) => {
  
    const selectedClient = clientData.activeClients.find(
      (data) => data.client_id === e
    );
    setHOME_USER_ID(e);
    setNavPath([{ id: 0, name: "Home", created_by: 0 }]);
    setHOME_FOLDER(selectedClient.s3_root_folder);
  };
  /////************ API DATA PROCESS************////////////
  useEffect(() => {
    dispatch(CLIENT_ACTION.getActiveClients());
  }, [dispatch]);

  useEffect(() => {
    if (HOME_USER_ID) {
      getFoldersFiles();
    }
  }, [navPath]);

  useEffect(() => {
    if (HOME_USER_ID) {
      setFileStructure((prev) => ({
        ...prev,
        files: userFiles,
      }));
    }
  }, [userFiles]);

  useEffect(() => {
    if (HOME_USER_ID) {
      setFileStructure((prev) => ({
        ...prev,
        folders: userFolders,
      }));
    }
  }, [userFolders]);

  const handleOpen = async (folder_name, id, created_by, index) => {
    if (index || index === 0) {
      const array = navPath.slice(0, index);
      setNavPath(array);
    }
    setNavPath((prev) => [
      ...prev,
      { id, name: folder_name, created_by: created_by },
    ]);
  };

  const clientFileUpload = async (formData, folderid) => {
    
    const USER_DATA = JSON.parse(localStorage.getItem("USER_DATA"));

    console.log("Client's Tax Advisor: ",USER_DATA);
    formData.append("path", HOME_FOLDER+"/To Preparer");
    formData.append("home_folder", HOME_FOLDER);
    formData.append("folder_name", "To Preparer");
    formData.append("folder_id", folderid);
    formData.append("user_id", HOME_USER_ID);
    formData.append("send_email", sendNotifications);
    formData.append("to_email", USER_DATA.employee_email);
    formData.append("from_email", "noreply@finlotax.com");
    formData.append("user_role", "client");
    formData.append("first_name", USER_DATA.employee);
    
    
       try {
          const response = await DOCUMENT_ACTION.uploadFile({formData,});
           dispatch(
              DOCUMENT_ACTION.getUserFiles({
              user_id: HOME_USER_ID,
              folder_name: "To Preparer",
              folder_id: folderid,
              })
              );
              if (response.status === 200 && response.data.status === 1) {
                 Toaster.success("Document upload successfully");
                 setModalOpen(false);
                } else {
                   Toaster.error("Error occurred while uploading the document");
                  }
                } catch (error) {
                    Toaster.error("Error occurred while uploading the document");
                }
     }

  const uploadFileHandler = async () => {

   console.log("Inside Upload Send to client data: ",selectedClientData);
   
    
    let path = getPath();
    let formData = new FormData();
    for (var i = 0; i <= fileData.length - 1; i++) {
      formData.append("file", fileData[i]);
    }
    //console.log("Path: ",path);
    console.log("Send Email Notifications status:",sendNotifications);
    if(localStorage.getItem("user_role") === "client")
    {
     // console.log("Existing Folders: ",userFolders);

      var folderExists = userFolders.some(folder => folder.folder_name === "To Preparer");

          if (folderExists) {
                console.log("The folder exists.");
               var toPreparerFolder  = userFolders.find(folder => folder.folder_name === "To Preparer");

              // console.log("To Preparer Folder ID: ",toPreparerFolder["client_folder_id"]);
               clientFileUpload(formData, toPreparerFolder["client_folder_id"]);
              
              
          } 
          else {
              // console.log("The folder does not exist.");
               try
               {const folderdata = await createFolderHandler("To Preparer");
               // console.log("Folder Data is : ",folderdata);
              // console.log("#### FOLDER ID: ",folderdata["client_folder_id"]);
               clientFileUpload(formData, folderdata["client_folder_id"]);

               }
               catch{
                console.log("ERROR CREATING TO PREPARER FOLDER..");
               }
               
             }
       }
    else{
    formData.append("path", getKeyPath());
    formData.append("home_folder", HOME_FOLDER);
    formData.append("directory_details", JSON.stringify(navPath));
    formData.append("folder_name", path.name);
    formData.append("folder_id", path.id);
    formData.append("user_id", HOME_USER_ID);
    formData.append("send_email", sendNotifications);
    formData.append("to_email", selectedClientData["email"]);
    formData.append("from_email", "noreply@finlotax.com");
    formData.append("user_role", "employee");
    formData.append("first_name", selectedClientData["first_name"]);

    //console.log("#### FOLDER ID: ",path.id)
    try {
      const response = await DOCUMENT_ACTION.uploadFile({
        formData,
      });
      dispatch(
        DOCUMENT_ACTION.getUserFiles({
          user_id: HOME_USER_ID,
          folder_name: path.name,
          folder_id: path.id,
        })
      );
      if (response.status === 200 && response.data.status === 1) {
        Toaster.success("Document upload successfully");
        setModalOpen(false);
      } else {
        Toaster.error("Error occurred while uploading the document");
      }
    } catch (error) {
      Toaster.error("Error occurred while uploading the document");
    }
  }
  };

  const createFolderHandler = async (newFolderName) => {
    let path = getPath();
   
    try {
      const response = await DOCUMENT_ACTION.createFolder({
        user_id: HOME_USER_ID,
        folderName: newFolderName,
        parentFolderName: path.name,
        parentFolderId: path.id,
        path: getKeyPath(),
        home_folder: HOME_FOLDER,
        directory_details: JSON.stringify(navPath),
      });

      dispatch(
        DOCUMENT_ACTION.getUserFolders({
          user_id: HOME_USER_ID,
          folder_name: path.name,
          folder_id: path.id,
        })
      );
      if(localStorage.getItem("user_role") !== "client")
        {    
      if (response.status === 200 && response.data.status === 1) {
        Toaster.success("Folder created successfully");
        
        setModalOpen(false);
        setNewFolderName("");
      } else {
        Toaster.error("Error occurred while creating the folder");
      }
    } else
    {
      if (response.status === 200 && response.data.status === 1) {
         console.log(" To preparer Folder created successfully"); 
        setNewFolderName("");
        //console.log("Folder data response: ",response.data.data);
        return response.data.data;
      }
      else { console.log(" Error occurred while creating To Preparer folder"); }
    }
    } catch (err) {
      Toaster.error("Error occurred while creating the folder");
    }
  
  

  };
  const handleDelete = async (docdata, folder_name, document_name) => {
    let path = getPath();
    try {
      await DOCUMENT_ACTION.deleteFile(docdata["client_document_id"],folder_name, document_name);
   dispatch(
    DOCUMENT_ACTION.getUserFiles({
      user_id: HOME_USER_ID,
      folder_name: folder_name,
      folder_id:  path.id,
    })
  );
}
catch (error) {
  console.error('Error deleting item:', error);
}
};
const handleSwitchChange = (event) => {
  const isChecked = event.target.checked;
  setSendNotifications(isChecked);
  console.log("Switch toggled. Send Notifications:", isChecked);
 
};
  const viewDownloadFileHandler = async (
    type,
    { folder_name, document_name, document_type }
  ) => {
   // console.log("ACTION IS: ",type);
   // console.log("ACTION DATA: ",document_name, folder_name, document_type);
    
    
    if (type === "Delete") {
      //console.log("USER FOLDERS: ",userFolders);
      var docdata = userFiles.find(file => file.document_name === document_name);
    
     handleDelete(docdata,folder_name,document_name);
      
    }
    else
    {
      const response = await DOCUMENT_ACTION.downloadViewFile({
        fileURL: `${getKeyPath()}/${document_name}`,
        folder_name,
        document_name,
        document_type,
        type,
      });
      if (type === "Preview") {
        setDocInfo({ RAW_DATA: response.raw, document_type });
        setModalOpen("preview");
      }
      if (response["status"] !== 200) {
        Toaster.error("Error occurred while viewing the document");
      }
    }
    
  };

  const modelAction = () => {
    if (isModalOpen === "add_folder") {
      if (newFolderName.trim() === "") {
        Toaster.warning("Please enter folder name");
        return;
      }
      createFolderHandler(newFolderName);
    } else if (isModalOpen === "upload") {
      if (fileData.length === 0) {
        Toaster.warning("Please upload document");
        return;
      }
      uploadFileHandler(fileData);
    }
  };

  const bulkDownloadHandler = async ({ folder_name, user_id, folder_id }) => {
    DOCUMENT_ACTION.getBulkFiles({
      user_id,
      folder_name,
      folder_id,
      path: getKeyPath(),
    });
  };
  var selectedClientData = "";
  const getAccess = (type) => {
    
       selectedClientData = clientData.activeClients.find(client => client.client_id === HOME_USER_ID);
    
      if (selectedClientData !== undefined)
      {
    return (
      
        (type === "activeclient" && (selectedClientData.employee_id === localStorage.getItem("user_id") || selectedClientData.sales === localStorage.getItem("user_id")) 
        || (localStorage.getItem("user_role") !== "admin" &&
        localStorage.getItem("user_id") ===
          navPath[navPath.length - 1].created_by) ||
      localStorage.getItem("user_role") === "admin" ) 
    );
  }
  };
  return (
    <>
      <FrameBox title="Documents - From Preparer">
        <div>
          <BreadCrumbs
            navPath={navPath}
            handleClick={(folder_name, id, created_by, index) =>
              handleOpen(folder_name, id, created_by, index)
            }
          />
          <div className="btn-wrapper">
            <div className="btn-wrapper-left">
              {getAccess("activeclient") && (
                <Button
                  disable={!HOME_USER_ID}
                  iconCls="btn_icon"
                  icon={<img src={AddFolder} alt="Add folder" />}
                  text="Add Folder"
                  handleClick={() => setModalOpen("add_folder")}
                />
              )}
              {localStorage.getItem("user_role") !== "client" && (
                <Labelbox
                  value={HOME_USER_ID}
                  type="select"
                  dropdown={clientsList}
                  labelname="Select Client"
                  changeData={(e) => chooseClient(e)}
                />
              )}
              {selectedClientData!== undefined  && localStorage.getItem("user_role") !=="client" && (
                <div className="login-user">{selectedClientData.first_name}&nbsp;{selectedClientData.last_name}&nbsp;({selectedClientData.email})</div>
              )}
            </div>
            {getAccess("activeclient") && (
              <Button
                 disable={!HOME_USER_ID}
                iconCls="btn_icon"
                icon={<img src={FileUpload} alt="File upload" />}
                text="Upload"
                handleClick={() => setModalOpen("upload")}
              />
            )}
          </div>
          <div>
          {!!fileStructure.folders.length &&
  fileStructure.folders
    .filter((data) => data.folder_name !== "To Preparer")
    .map((data) => (
      <FileFolderCard
        key={data.client_folder_id} // Add a unique key for better React performance
        modifiedAt={dateTimeFormatter(data.updatedAt)}
        name={data.folder_name}
        details={data}
        type={"File Folder"}
        onMenuClick={(
          item,
          { folder_name, client_id, client_folder_id }
        ) => {
          bulkDownloadHandler({
            user_id: client_id,
            folder_name,
            folder_id: client_folder_id,
          });
        }}
        id={data.client_folder_id}
        options={["Download"]}
        onClick={(folder_name, id) =>
          handleOpen(folder_name, id, data.created_by)
        }
      />
    ))}
            {!!fileStructure.files.length &&
              fileStructure.files.filter((data) => data.folder_name !== "To Preparer")
              .map((data) => (
                <FileFolderCard
                key={data.client_folder_id}
                  modifiedAt={dateTimeFormatter(data.updatedAt)}
                  document_type={data.document_type}
                  document_extension={data.document_extension}
                  size={`${Math.round(data.document_size / 1024)} kb`}
                  name={data.document_name}
                  type={"Document"}
                  details={data}
                  onMenuClick={viewDownloadFileHandler}
                  options={["Preview", "Download", "Delete"]}
                  
                />
              ))}
            {!fileStructure.files.length && !fileStructure.folders.length && (
              <Empty />
            )}
          </div>
        </div>
      </FrameBox>
      <br></br><br></br>
      
      <FrameBox title="Documents - To Preparer">
        <br></br>
      {localStorage.getItem("user_role") === "client" && getKeyPath() === HOME_FOLDER && (
         <div className="center-container">
              <Button
                class="clientupload"
                iconCls="btn_icon"
                icon={<img src={FileUpload} alt="File upload" />}
                text="Upload"
                handleClick={() => setModalOpen("upload")}
              />
              </div>
            )}
            <br></br>
      {selectedClientData!== undefined  && localStorage.getItem("user_role") !=="client" && (
                <div className="login-user">Files From {selectedClientData.first_name}&nbsp;{selectedClientData.last_name}&nbsp;({selectedClientData.email})</div> )}
              <br></br>
              <div>
               {!!fileStructure.folders.length &&
  fileStructure.folders
    .filter((data) => data.folder_name === "To Preparer")
    .map((data) => (
      <FileFolderCard
      disable
        key={data.client_folder_id} // Add a unique key for better React performance
        modifiedAt={dateTimeFormatter(data.updatedAt)}
        name={data.folder_name}
        details={data}
        type={"File Folder"}
        onMenuClick={(
          item,
          { folder_name, client_id, client_folder_id }
        ) => {
          bulkDownloadHandler({
            user_id: client_id,
            folder_name,
            folder_id: client_folder_id,
          });
        }}
        id={data.client_folder_id}
        options={["Download"]}
        onClick={(folder_name, id) =>
          handleOpen(folder_name, id, data.created_by)
        }
      />
    ))}
            {!!fileStructure.files.length &&
              fileStructure.files.filter((data) => data.folder_name === "To Preparer")
              .map((data) => (
                <FileFolderCard
                  modifiedAt={dateTimeFormatter(data.updatedAt)}
                  document_type={data.document_type}
                  document_extension={data.document_extension}
                  size={`${Math.round(data.document_size / 1024)} kb`}
                  name={data.document_name}
                  type={"Document"}
                  details={data}
                  onMenuClick={viewDownloadFileHandler}
                  options={["Preview", "Download", "Delete"]}
                  
                />
              ))}
            {!fileStructure.files.length && !fileStructure.folders.length && (
              <Empty />
            )}
          </div>
              
      </FrameBox>
     
      {isModalOpen && (
  <Modal
    title={
      isModalOpen === "upload"
        ? "Upload a file"
        : isModalOpen === "preview"
        ? "Preview"
        : "Add a new folder"
    }
    open={isModalOpen}
    handleClose={() => setModalOpen(false)}
    maxWidth={isModalOpen === "preview"}
  >
    <div className="add_folder_modal">
      {isModalOpen === "upload" ? (
        <>
          <Labelbox
            type="upload"
            changeData={(data) => setFileData(data)}
            value={fileData}
          />

          {/* Send Notifications Section */}
          <div style={{ marginTop: '10px' }}>
            <FormControlLabel
              control={
                <Switch 
                  checked={sendNotifications} 
                  onChange={handleSwitchChange} 
                  name="sendEmail" 
                />
              }
              label="Send Email Notifications"
            />
          </div>
        </>
      ) : isModalOpen !== "preview" ? (
        <Labelbox
          type="text"
          changeData={(data) =>
            setNewFolderName(data.replace(/[^\w ]/g, ""))
          }
          value={newFolderName}
          placeholder="Enter name"
        />
      ) : (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          src={docInfo.RAW_DATA}
          type={docInfo.document_type}
          style={{ width: "90vw", height: "80vh", overflow: "auto" }}
        />
      )}
      {isModalOpen !== "preview" && (
        <Button
          text={isModalOpen === "upload" ? "Upload" : "Add"}
          handleClick={() => modelAction({ 
            fileData, 
            newFolderName, 
            sendNotifications, 
            notificationLevel 
          })}
        />
      )}
    </div>
  </Modal>
)}

    </>
  );
};

export default UploadDoc;
