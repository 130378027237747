/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable require-jsdoc */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import {
  Edit,
  Delete,
  CloudUpload,
  RemoveCircleOutline,
  Visibility,
  PrintRounded,
  GetAppRounded,
  Email,
  Done,
  Clear,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import * as FinloComponent from "../index";
import ValidationLibrary from "../../utils/validationfunction";
import Empty from "antd/es/empty";
import "./table.scss";
import { FileConverter, File, Undo } from "../../images";
import { getNodeText } from "../../utils/common";
import { Grid } from "@material-ui/core";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();

  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

/**
 * @function descendingComparator used for descendingComparator action
 * @param {string} a input value
 * @param {string} b input value
 * @param {string} orderBy input value
 * @returns {string} value
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * @function getComparator used for descendingComparator action
 * @param {string} order input value
 * @param {string} orderBy input value
 * @returns {string} value
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * @function stableSort used for descendingComparator action
 * @param {string} array input value
 * @param {string} comparator input value
 * @returns {string} value
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, headCells, EnableSno } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={`tableHead`}>
      <TableRow>
        {EnableSno && (
          <TableCell padding="checkbox" className="tableHeadsno">
            S.No
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            // hideSortIcon={headCell.id?true:false}
          >
            {headCell.id ? (
              <TableSortLabel
                active={props.sort && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className={props.sort ? null : classes.nopointer}
                hideSortIcon={orderBy === headCell.id}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // paddingTop: "1.2rem",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  inactiveSortIcon: {
    display: "none",
  },
  default: {
    color: "#EE8E54",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  NormalTable: {
    color: "black",
  },
  nopointer: {
    cursor: "unset !important",
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const EnhancedTable = ({
  header, //FYR:: contains array of object with header id and header name eg:[{id:1,label:"Name"},{id:2,label:"Age"},]
  data, //FYR:: contains array of objects determines table's row and each attribute determines table cell eg:data=[{name:"",age:"",..},{name:"",age:"",..}]
  title, //FYR:: title of table's framebox
  aligncss, //FYR:: common classname for entire table
  EnableSno, //FYR:: shows S.No for all rows in table
  disableID,
  actions, //FYR:: contains array of icons name to be displayed under Action column eg:actions=["edit","delete",..]. NOTE: mandatory to give icon name here even incase of restrictActionIcon props
  onActionClick, //FYR:: callback func when action icons click event fired
  search, //FYR:: shows searchbox for table data search sorting
  fields, //FYR:: contains array of labelbox fields with respective attributes for respective column eg:fields=[{type:"text",labelname:"Name"..},{type:"checkbox"..}]
  actionElements, //FYR:: contains custom node rendered at top of table head
  variant, //FYR:: variant of framebox determines size of table's framebox title eg:variant="small"
  customRow, //FYR:: contains node consist of custom no of TableCells eg:customRow=<><TableCell rowSpan={8}>Total</TableCell><TableCell rowSpan={2}>$500</TableCell><>. NOTE: A last row of entire row
  actionDefault,
  icons, //FYR:: contains common icons to be displayed for all rows eg:icons={eyeIcon: true,notAvail:true}. NOTE:header=[{id:1,label:""},{id:2,label:""},{id:3,label:"Name"},..etc]
  validateRef, //FYR:: contains a ref to validation table labelbox controls from parent page's onclick event. Returns boolean result of validation in ref.current
  cancelValidate, //FYR:: contains a ref to cancel all validation to initial state from parent page's onclick event
  restrictActionIcon, //FYR:: action icons will show/hide based on individual entry eg:rows=[{name:"",age:""...,enabledActions:{edit:true,delete:true,..},{name:"",age:""...,enabledActions:{download:true,print:true,..}}]
  toggleDisable, //FYR:: disable/enable labelbox controls of particular row on toggling that particular row's checkbox. NOTE: toggling field entry should has toggleDisableCheck attr eg:fields=[{type:checkbox,toggleDisableCheck:true,..}]
  hiddenFields, //FYR:: array contains fields name that do not visible in table eg:hiddenFields=["name","age"]. NOTE: No header/ fields should pass for that columns
  filters, //FYR:: array of objects contains field controls attributes to filter table data eg:filters=[{type:"select",...}...]
  filterCallback, //FYR:: callback function which return onchange value, field key
  fileData,
  showExporter,
  // restrictStatusButton,
}) => {

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [validationList, setValidationList] = React.useState([]);
  const [enabledRows, setEnableRows] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * @function escapeRegExp used for escapeRegExp action
   * @param {string} value input field data
   * @returns {string} key input field name
   */
  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }
  useMemo(() => {
    if (fields) {
      if (!!data.length) {
        const initialValidation = fields.map((_) => {
          return { error: null, errmsg: null };
        });
        if (validationList.length === 0 && data.length === 1) {
          setValidationList([initialValidation]);
        } else if (data.length > validationList.length) {
          const diff = data.length - validationList.length;
          for (let i = 1; i <= diff; i++) {
            validationList.push(initialValidation);
          }
          setValidationList((prevState) => [...prevState]);
        }
      } else {
        setValidationList([]);
      }
    }
  }, [data.length]);

  function onSubmitValidation() {
    if ((validateRef, fields)) {
      let validateRows = toggleDisable
        ? rows.filter((_, index) => enabledRows.includes(`R${index}`))
        : rows;
      const deepList = JSON.parse(JSON.stringify(validationList));
      const filterError = validateRows.filter((item, rowIndex) => {
        const index = toggleDisable
          ? enabledRows[rowIndex].replace("R", "")
          : rowIndex;
        const errorList = Object.entries(item).filter(
          ([key, value], colIndex) => {
            if (!hiddenFields?.includes(key)) {
              const errorcheck = ValidationLibrary.checkValidation(
                value,
                fields[colIndex].validation
              );
              deepList[index][colIndex].error = !errorcheck.state;
              deepList[index][colIndex].errmsg = errorcheck.msg;
              return !errorcheck.state;
            }
          }
        );
        return !!errorList.length;
      });
      setValidationList(deepList);
      return filterError.length > 0;
    }
  }

  function onCancelValidation() {
    const initialValidation = fields.map((_) => {
      return { error: null, errmsg: null };
    });
    setValidationList([[...initialValidation]]);
  }

  if (validateRef) validateRef.current = onSubmitValidation;

  if (cancelValidate) cancelValidate.current = onCancelValidation;

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {number} colIndex table field index
   * @param {number} rowIndex table row index
   */
  function checkValidation(data, colIndex, rowIndex) {
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      fields[colIndex].validation
    );
    validationList[rowIndex][colIndex] = {
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
    };
    setValidationList([...validationList]);
  }

  useEffect(() => {
    if (data) {
      setRows(data);
      setPage(0);
    }
  }, [data]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, index) => {
    const selectedIndex = selected.indexOf(name);
    setSelectedRow(index);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // eslint-disable-next-line radix
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const [searchText, setSearchText] = React.useState("");

  //-------- To find whether table header has action provision ----------//
  const isAction = useMemo(() => {
    if (header) {
      let actionIndex;
      // const actionIndex = header.findIndex((head) => head.label === "Action");
      const noheaderFields = header.filter((head, index) => {
        if (head.label === "Action" && !actionIndex) actionIndex = index;
        else if (head.label === "") return head;
      });
      const rowIndex = actionIndex - noheaderFields?.length;
      return rowIndex || null;
    }
  }, [header]);

  const changeEnableMode = (rowIndex) => {
    const isEnabled = enabledRows.findIndex((ind) => ind === `R${rowIndex}`);
    if (isEnabled === -1) {
      enabledRows.push(`R${rowIndex}`);
    } else {
      const list = JSON.parse(JSON.stringify(validationList));
      list[rowIndex] = list[rowIndex]?.map((rowitem) => {
        if (rowitem.error) {
          return { error: null, errmsg: null };
        } else return rowitem;
      });
      setValidationList(list);
      enabledRows.splice(isEnabled, 1);
    }
  };

  /**
   * @function escapeRegExp used for escapeRegExp action
   * @param {string} value input field data
   * @returns {string} key input field name
   */
  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if (searchValue === "") {
      setRows(data);
    } else {
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows =
        data &&
        data.length > 0 &&
        data.filter((row) => {
          return Object.keys(row).some((field) => {
            return searchRegex.test(getNodeText(row[field])?.toString());
          });
        });

      setRows(filteredRows || []);
    }
  };
  useMemo(() => {
    if (filters) setFilterOptions(filters);
  }, [filters]);

  const onFilterChange = (targetValue, filter, index) => {
    if (filter.type === "checkbox") filterOptions[index].checked = targetValue;
    else filterOptions[index].value = targetValue;
    filterCallback(targetValue, filter.key);
    setFilterOptions([...filterOptions]);
  };
  const ICONS = {
    edit: <Edit fontSize="small" />,
    delete: <Delete fontSize="small" />,
    update: <CloudUpload fontSize="small" />,
    convert: <img src={FileConverter} alt="converter" />,
    print: <PrintRounded fontSize="small" />,
    download: <GetAppRounded fontSize="small" />,
    email: <Email fontSize="small" />,
    done: <Done fontSize="small" />,
    file: <img src={File} alt="filr" />,
    undo: <img src={Undo} alt="undo" />,
    close: <Clear fontSize="small" />,
  };
  return (
    <div className={classes.root}>
      <FinloComponent.FrameBox title={title} transparent variant={variant}>
        {search && (
          <div className="search_box">
            <FinloComponent.Labelbox
              type="searchbox"
              value={searchText}
              changeData={(e) => requestSearch(e)}
            />
          </div>
        )}
        {actionElements && (
          <div className="action-elements">{actionElements}</div>
        )}
        {!!filterOptions?.length && (
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ padding: "5px" }}
          >
            {filterOptions.map((filter, index) => (
              <Grid item xs={12} ms={3} sm={3} lg={2}>
                <FinloComponent.Labelbox
                  {...filter}
                  changeData={(targetValue) =>
                    onFilterChange(targetValue, filter, index)
                  }
                />
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              ms={3}
              sm={3}
              lg={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              <FinloComponent.FileFormat fileData={fileData} title={title} />
            </Grid>
          </Grid>
        )}
        {/* {showExporter && (
          <Grid
            item
            md={1}
            xs={12}
            sm={12}
            container
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "end",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            <FinloComponent.FileFormat fileData={fileData} title={title} />
          </Grid>
        )} */}
    <TableContainer>
  <Table
    className={`${classes.table} ${aligncss}`}
    aria-labelledby="tableTitle"
    size="small"
    aria-label="enhanced table"
  >
    <EnhancedTableHead
      classes={classes}
      numSelected={selected.length}
      order={order}
      orderBy={orderBy}
      onSelectAllClick={handleSelectAllClick}
      EnableSno={EnableSno}
      onRequestSort={handleRequestSort}
      rowCount={rows.length}
      headCells={header}
      className={aligncss}
    />
    <TableBody>
      {rows.length > 0 ? 
      
      (
        stableSort(rows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const actionIndex = rowsPerPage * page + index;
            var keys = Object.keys(row);
            keys = hiddenFields
              ? keys.filter((item) => !hiddenFields?.includes(item))
              : keys;
            const actionKeys = keys.filter((key) =>
              ["enabledActions", "notAvail"].includes(key)
            );
            const arrval = [];
            var terminator =
              isAction && isAction !== -1 && actions
                ? keys.length + 1
                : keys.length;
            terminator = !!actionKeys.length
              ? terminator - actionKeys.length
              : terminator;
            for (let m = 0; m < terminator; m++) {
              if (isAction === m && actions) {
                // Show action buttons based on action types
                arrval.push(
                  <TableCell key={`${index}${m}`} align="left">
                    <div className="row-action">
                      {actions.map((action) =>
                        [
                          "edit",
                          "delete",
                          "update",
                          "convert",
                          "print",
                          "download",
                          "email",
                          "done",
                          "file",
                          "undo",
                          "close",
                        ].includes(action) ?
                         (
                          <div
                            className={`${
                              !restrictActionIcon ||
                              row?.enabledActions?.[action]
                                ? "show"
                                : "hide"
                            } ${action}`}
                            onClick={() => {
                              onActionClick(action, row, actionIndex);
                              if (action === "delete")
                       
                                validationList.splice(index, 1);
                            }}
                          >
                            <Tooltip
                              title={
                                action === "done"
                                  ? "Mark us paid"
                                  : action.capitalize()
                              }
                            >
                              {ICONS[action]}
                            </Tooltip>
                          </div>
                        ) 
                        : action.type &&
                          ((action.showPrivilege?.value.length &&
                            (action.showPrivilege?.value).includes(
                              row[action.showPrivilege?.key]
                            )) ||
                            !action.showPrivilege) ? (
                          <div className={action.hide && "hide"}>
                            {React.createElement(FinloComponent[action.type], {
                              ...action,
                              handleClick: () =>
                                onActionClick(action, row, actionIndex),
                            })}
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </TableCell>
                );
              } else if (!disableID && keys[m] !== "notAvail") {
                // Show SNO or other data cells
                arrval.push(
                  <TableCell
                    key={`${index}${m}`}
                    align="left"
                    className={fields ? "align-cell-top" : ""}
                  >
                    {fields?.length > 0 ? (
                      <FinloComponent.Labelbox
                        {...fields[m]}
                        dropdown={
                          fields[m]?.dependentDropdown?.length > 0
                            ? fields[m]?.dependentDropdown[
                                rowsPerPage * page + index
                              ]
                            : fields[m]?.dropdown || []
                        }
                        value={
                          row[keys[m]] ||
                          `${fields[m]?.type === "label" ? "---" : ""}`
                        }
                        checked={row[keys[m]] || false}
                        changeData={(e) => {
                          fields[m].changeData(
                            e,
                            keys[m],
                            rowsPerPage * page + index
                          );
                          if (
                            fields[m]?.type === "checkbox" &&
                            fields[m]?.toggleDisableCheck
                          ) {
                            changeEnableMode(actionIndex);
                          } else {
                            checkValidation(e, m, actionIndex);
                          }
                        }}
                        disabled={
                          toggleDisable &&
                          !fields[m]?.toggleDisableCheck
                            ? !enabledRows.includes(`R${actionIndex}`)
                            : fields[m].disabled || false
                        }
                        error={validationList?.[actionIndex]?.[m]?.error}
                        errmsg={validationList?.[actionIndex]?.[m]?.errmsg}
                      />
                    ) : row[keys[m]] ? (
                      <div>{row[keys[m]]}</div>
                    ) : (
                      "---"
                    )}
                  </TableCell>
                );
              }
            }

            return (
              <React.Fragment key={index}>
                <StyledTableRow
                  hover
                  onClick={(event) => handleClick(event, row.name, row.id)}
                  role="checkbox"
                  aria-checked={row.id === selectedRow}
                  tabIndex={-1}
                  selected={row.id === selectedRow}
                >
                  {/* Render S.no */}
                  {EnableSno && (
                    <TableCell align="center">
                      {rowsPerPage * page + index + 1}
                    </TableCell>
                  )}

                  {/* Render eye icon if enabled */}
                  {icons?.eyeIcon && (
                    <TableCell align="center">
                      <div
                        onClick={() => onActionClick("eye", row, actionIndex)}
                        style={{ cursor: "pointer" }}
                      >
                        <Visibility />
                      </div>
                    </TableCell>
                  )}

                  {/* Render "Not Available" indicator if enabled */}
                  {icons?.notAvail && (
                    <TableCell align="center">
                      {row.notAvail ? (
                        <div className="do-not-disturb">
                          <RemoveCircleOutline />
                        </div>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  )}

                  {/* Render other cells */}
                  {arrval}
                </StyledTableRow>

                {/* Custom row, if applicable */}
                {customRow &&
                  rowsPerPage * page + index + 1 === rows.length && (
                    <StyledTableRow>{customRow}</StyledTableRow>
                  )}
              </React.Fragment>
            );
          })
      ) : (
        <TableRow>
          <TableCell colSpan={12} className="nodatafound">
            <Empty />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
</TableContainer>

        {rows.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 15, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </FinloComponent.FrameBox>
    </div>
  );
};
export default React.memo(EnhancedTable);
