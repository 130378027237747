import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import TokenService from "../../utils/token-service";

const assignedBy = (params) => {
  return TokenService.getUserRole() === "employee"
    ? { ...params, billed_by: TokenService.getUserID() }
    : params;
};
const insertUpdateInvoice = async (state) => {
  try {
    const { params, insertUpdateFlag, client_invoice_id } = state;

    const API_NAME =
      insertUpdateFlag === 0
        ? "invoice/createInvoice"
        : `invoice/updateInvoiceById/${client_invoice_id}`;
    const data = await API.post(`${API_NAME}`, params, {
      headers: {
        user_type: localStorage.getItem("user_role") === "client" ? 2 : 1,
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};

const convertInvoice = async (state) => {
  try {
    const data = await API.get(`invoice/convertInvoiceByContractId/${state}`);
    return data;
  } catch (error) {
    return [];
  }
};

const getAllInvoices = createAsyncThunk(
  "invoice/getAllInvoicesStatus",
  async (params = {}) => {
    try {
      const {
        data: { data },
      } = await API.post(`invoice/getAllInvoice`, assignedBy(params));
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getCollectionInvoices = createAsyncThunk(
  "invoice/getCollectionInvoicesStatus",
  async (params = {}) => {
    try {
      const {
        data: { data },
      } = await API.post(`invoice/getAllInvoice?collection=true`, params);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getInvoiceDetail = async (invoice_id) => {
  try {
    const data = await API.get(`invoice/getInvoiceById/${invoice_id}`);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const invoicePaidUpdate = async (payload, type = "") => {
  try {
    return await API.post(`payment/${type}`, payload).then((response) => {
      if (response.status === 200 && response.data.status === 1)
        return { status: "Success", msg: response.message };
      else return { msg: response.message };
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};


const deleteInvoiceById = async (Invoice_id) => {
  try {
    const data = await API.delete(`invoice/deleteInvoiceById/${Invoice_id}`);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export {
  deleteInvoiceById,
  insertUpdateInvoice,
  getInvoiceDetail,
  getAllInvoices,
  convertInvoice,
  getCollectionInvoices,
  invoicePaidUpdate,
};
