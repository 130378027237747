import React from "react";
import Empty from "antd/es/empty";

import "./infotable.scss";

export default function InfoTable({
  titles,
  info,
  showColon,
  outlined,
  transparent,
}) {
  return (
    <div className={`toolBox ${outlined && "outline"}`}>
      {info ? (
        <table>
          <tbody>
            {Object.entries(info)?.map(([_, value], index) => {
              if (!transparent && !value) {
                // eslint-disable-next-line array-callback-return
                return;
              }
              return (
                <tr key={index}>
                  {titles && (
                    <td className={"toolDescription"}>{titles[index]}</td>
                  )}
                  {titles && (showColon ? <td>:</td> : <td>&nbsp;&nbsp;</td>)}
                  <td>{value || "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Empty />
      )}
    </div>
  );
}
