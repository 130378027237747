import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

const getMasterData = createAsyncThunk(
  "client/masterDataStatus",
  async ({ name, id }) => {
    try {
      if (!id) {
        const {
          data: { data },
        } = await API.get(`master/getMasterByType/${name}`);
        return data;
      } else {
        const {
          data: { data },
        } = await API.post(`master/postMasterByType`, {
          type: name,
          id,
        });
        return data;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getNotification = async () => {
  try {
    const {
      data: { data },
    } = await API.get(`notification/get_notification`);
    console.log("getNotificatin result", data)
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const updateNotification = async (notification_id) => {
  try {

    const data = await API.get(
      `notification/update_notification/${notification_id}`
    );
    console.log("before update api notification is triggred data",data)
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getSubActivity = async (activity_id) => {
  try {
    const response = await API.get(
      `master/getSubActivity${activity_id ? "?activity_id=" + activity_id : ""}`
    );
    return response?.status === 200
      ? response.data.data
      : response.data.message;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};

const getSubActivityDetails = createAsyncThunk(
  "client/mastdserDataStatus",
  async (activity_id) => {
    try {
      const {
        data: { data },
      } = await API.get(
        `master/getSubActivity${
          activity_id ? "?activity_id=" + activity_id : ""
        }`
      );
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const insertSubActivity = async (params) => {
  try {
    const API_NAME = "master/insertSubActivity";
    const response = await API.post(`${API_NAME}`, params);
    return response?.status === 200
      ? response.data.data
      : response.data.message;
  } catch (error) {
    return [];
  }
};

const deleteSubActivity = async (params) => {
  try {
    const API_NAME = "master/deleteSubActivity";
    const { data } = await API.get(`${API_NAME}/${params}`);
    return data;
  } catch (error) {
    return [];
  }
};

export {
  getMasterData,
  getNotification,
  updateNotification,
  getSubActivity,
  getSubActivityDetails,
  insertSubActivity,
  deleteSubActivity,
};
