import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

const insertUpdateTimesheet = async (state) => {
  try {
    const { params, insertUpdateFlag, timesheet_id } = state;

    const API_NAME =
      insertUpdateFlag === 0
        ? "timesheet/createTimesheet"
        : `timesheet/updateTimesheet/${timesheet_id}`;
    const data = await API({
      method: insertUpdateFlag === 0 ? "post" : "put",
      url: `${API_NAME}`,
      data: params,
      headers: {
        user_type: localStorage.getItem("user_role") === "client" ? 2 : 1,
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};

const getAllTimesheets = createAsyncThunk(
  "timesheet/getAllTimesheetsStatus",
  async (params = {}) => {
    try {
      const {
        data: { data },
      } = await API.post(`timesheet/getAllTimesheet`, params);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const approveTimesheet = async (timesheet_id) => {
  try {
    const response = await API.get(
      `timesheet/approveTimesheet/${timesheet_id}`
    );
    if (response.status === 200)
      return { status: true, message: "Timesheet approved successfully" };
    else return { status: false, message: response.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const rejectTimesheet = async (params) => {
  try {
    const response = await API.put(`timesheet/rejectTimesheet`, params);
    if (response.status === 200)
      return { status: true, message: "Timesheet rejected successfully" };
    else return { status: false, message: response.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const getTimesheetEntries = async (params = {}) => {
  try {
    const response = await API.put(`timesheet/getTimesheetDetails`, params);
    if (response.status === 200) return response.data.data;
    else return response.message;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};

export {
  insertUpdateTimesheet,
  getAllTimesheets,
  approveTimesheet,
  rejectTimesheet,
  getTimesheetEntries,
};
