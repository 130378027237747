import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const useStyles = makeStyles(() => ({
  /* Regular Popup */
  DialogTitle: {
    "&.MuiDialogTitle-root": {
      backgroundColor: "#023E7D",
      position: "relative",
      padding: "4px 0px 4px 10px",
    },
    "& h2": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "1rem",
    },
  },
  title: {
    color: "#FFFFFF",
  },
  closeIcon: {
    padding: 10,
    color: "#FFFFFF",
    "& svg": {
      height: "16px",
      width: "16px",
    },
  },
  dialogBox: {
    position: "relative",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
  },
  DialogContent: {
    padding: 15,
    overflowX: "hidden",
  },
}));

const Modal = (props) => {
  const classes = useStyles();
  const { title, children, open, handleClose, maxWidth, className } = props;
  return (
    <Dialog
      classes={{ paper: classes.dialogBox }}
      open={Boolean(open)}
      onClose={handleClose}
      fullWidth={maxWidth && true}
      maxWidth={maxWidth && "90vw"}
      className={className || ""}
    >
      <DialogTitle className={classes.DialogTitle}>
        {title && <span className={classes.title}>{title}</span>}
        <IconButton
          onClick={handleClose}
          aria-label="close"
          className={classes.closeIcon}
        >
          <CancelOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
