import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import TokenService from "../../utils/token-service";

const assignedBy = (seperator) => {
  return TokenService.getUserRole() === "manager"
    ? seperator + 'assigned_by="' + TokenService.getUserID() + '"'
    : "";
};
const insertUpdateEmployee = async (state) => {
  try {
    const { params, insertUpdateFlag, employee_id } = state;
    const API_NAME =
      insertUpdateFlag === 0
        ? "employee/createEmployee"
        : `employee/updateEmployeeById/${employee_id}`;
    const data = await API.post(`${API_NAME}`, params);
    return data;
  } catch (error) {
    return [];
  }
};

const getActiveEmployees = createAsyncThunk(
  "employee/getActiveEmployeesStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(
        `employee/getAllEmployee?active=true${assignedBy("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getAllEmployees = createAsyncThunk(
  "employee/getAllEmployeesStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(`employee/getAllEmployee${assignedBy("?")}`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getActiveManagers = createAsyncThunk(
  "employee/getActiveManagersStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(`employee/getAllEmployee?active=true&manager=true`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getEmployee = createAsyncThunk(
  "employee/getEmployeeStatus",
  async (state) => {
    try {
      if (state === "null" || state === "undefined") {
        return {};
      } else {
        const {
          data: { data },
        } = await API.get(`employee/getEmployeeById/${state}`);
        return data;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

export {
  insertUpdateEmployee,
  getActiveEmployees,
  getEmployee,
  getAllEmployees,
  getActiveManagers,
};
