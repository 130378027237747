import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate , useLocation} from "react-router-dom";
import { Labelbox } from "../../components";
import ValidationLibrary from "../../utils/validationfunction";
import { AUTH_ACTION } from "../../redux/action";
import { Toaster, encryptPassword } from "../../utils/common";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const tempPassword = searchParams.get("tempPassword");

  const [inputs, setInputs] = useState({
    password: {
      type: "text",
      labelname: "Password",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      password: true,
    },
    confirmPassword: {
      type: "text",
      labelname: "Confirm Password",
      value: "",
      validation: [
        { name: "required" },
        { name: "comparePassword", compareText: "" },
      ],
      error: null,
      errmsg: null,
      password: true,
    },
  });

  // useEffect(() => {
  //   if (!email || !tempPassword) {
  //     Toaster.error("Invalid or missing reset link parameters.");
  //   }
  // }, [email, tempPassword]);

  const location = useLocation();
  const [token, setToken] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    setToken(token);
  }, [location]);


  const handleChange = (value, fieldKey) => {
    if (fieldKey === "confirmPassword")
      inputs[fieldKey].validation[1].compareText = inputs.password.value;

    const errorcheck = ValidationLibrary.checkValidation(
      value,
      inputs[fieldKey].validation
    );
    inputs[fieldKey].value = value;
    inputs[fieldKey].error = !errorcheck.state;
    inputs[fieldKey].errmsg = errorcheck.msg;
    setInputs({ ...inputs });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errorList = Object.keys(inputs).filter((fieldKey) => {
        const errorcheck = ValidationLibrary.checkValidation(
          inputs[fieldKey].value,
          inputs[fieldKey].validation
        );
        inputs[fieldKey].error = !errorcheck.state;
        inputs[fieldKey].errmsg = errorcheck.msg;
        return !errorcheck.state;
      });
      setInputs({ ...inputs });
      if (!errorList.length) {
        const status = await AUTH_ACTION.authService(
          {
            email,
            tempPassword,
            newPassword: encryptPassword(inputs.password.value),
            token:token
          },
          "resetAuth"
        );
        if (status === "Success") {
          Toaster.success("Password has been reset successfully");
          navigate("/");
        } else Toaster.error(status);
      }
    } catch (error) {
      console.error(error);
      Toaster.error("An error occurred while resetting the password.");
    }
  };

  return (
    <div>
      <div
        className="signup_container align_column"
        style={{ height: "100vh" }}
      >
        <div
          className="signup_block align_column credential_card"
          style={{ height: "auto" }}
        >
          <div className="signup_info credential_title">
            <h2 className="welcome">Reset Password</h2>
          </div>
          <form action="" className="signup-form input-warpper">
            <div className="input_block">
              {Object.keys(inputs)?.map((fieldKey, index) => (
                <Labelbox
                  key={index}
                  type={inputs[fieldKey].type}
                  labelname={inputs[fieldKey].labelname}
                  value={inputs[fieldKey].value}
                  changeData={(value) => handleChange(value, fieldKey)}
                  error={inputs[fieldKey].error}
                  errmsg={inputs[fieldKey].errmsg}
                  password={inputs[fieldKey].password}
                />
              ))}
            </div>
            <center>
              <button
                className="signin_button action_btn"
                onClick={handleSubmit}
              >
                Reset
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
