import React, { useState } from "react";
import { Labelbox } from "../../components";
import { useNavigate } from "react-router-dom";
import ValidationLibrary from "../../utils/validationfunction";
import { AUTH_ACTION } from "../../redux/action";
import { Toaster, encryptPassword } from "../../utils/common";

const ChangePassword = () => {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    currentPassword: {
      type: "text",
      labelname: "Current Password",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      password: true,
    },
    newPassword: {
      type: "text",
      labelname: "New Password",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      password: true,
    },
    confirmPassword: {
      type: "text",
      labelname: "Confirm Password",
      value: "",
      validation: [
        { name: "required" },
        { name: "comparePassword", compareText: "" },
      ],
      error: null,
      errmsg: null,
      password: true,
    },
  });

  const handleChange = (value, fieldKey) => {
    if (fieldKey === "confirmPassword") {
      inputs[fieldKey].validation[1].compareText = inputs.newPassword.value;
    }

    const errorcheck = ValidationLibrary.checkValidation(
      value,
      inputs[fieldKey].validation
    );
    inputs[fieldKey].value = value;
    inputs[fieldKey].error = !errorcheck.state;
    inputs[fieldKey].errmsg = errorcheck.msg;
    setInputs({ ...inputs });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errorList = Object.keys(inputs).filter((fieldKey) => {
        const errorcheck = ValidationLibrary.checkValidation(
          inputs[fieldKey].value,
          inputs[fieldKey].validation
        );
        inputs[fieldKey].error = !errorcheck.state;
        inputs[fieldKey].errmsg = errorcheck.msg;
        return !errorcheck.state;
      });
      setInputs({ ...inputs });
      if (!errorList.length) {
        const status = await AUTH_ACTION.authService(
          {
            old_password: encryptPassword(inputs.currentPassword.value),
            new_password: encryptPassword(inputs.newPassword.value),
            email: localStorage.getItem("email"),
          },
          "newAuth"
        );
        if (status === "Success") {
          Toaster.success("Password has been changed successfully");
          navigate("/");
        } else Toaster.error(status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="signup_container align_column" style={{ height: "100%" }}>
        <div
          className="signup_block align_column  credential_card"
          style={{ height: "auto" }}
        >
          <div className="signup_info credential_title">
            <h2 className="welcome">Change New Password</h2>
          </div>
          <form action="" className="signup-form input-warpper">
            <div className="input_block">
              {Object.keys(inputs)?.map((fieldKey, index) => (
                <Labelbox
                  type={inputs[fieldKey].type}
                  labelname={inputs[fieldKey].labelname}
                  value={inputs[fieldKey].value}
                  changeData={(value) => handleChange(value, fieldKey)}
                  error={inputs[fieldKey].error}
                  errmsg={inputs[fieldKey].errmsg}
                  password={inputs[fieldKey].password}
                />
              ))}
            </div>
            <center>
              <button
                className="signin_button action_btn"
                onClick={handleSubmit}
              >
                Change Password
              </button>
            </center>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
