/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Excel from '../FileDownloader';

/**
 * @name FileFormat
 * @param {object} props required props
 * @param {object} props.fileData required props
 * @param {string} props.title required props
 * @returns {React.ReactElement} -- returns the file generator with dropdowns
 */
const FileFormat = ({ fileData, title }) => {
  return (
    <Grid container columnGap={1}>
      {/* <img src={CustomIcons.WordIcon} alt="word" className="downloadIcons" />
      <img src={CustomIcons.ExcelIcon} alt="word" className="downloadIcons" />
      <img src={CustomIcons.PdfIcon} alt="word" className="downloadIcons" /> */}
      <Excel fileData={fileData} title={title} />
    </Grid>
  );
};
FileFormat.propTypes = {
  fileData: PropTypes.array,
  title: PropTypes.string,
};
FileFormat.defaultProps = {
  fileData: [],
  title: null,
};
export default FileFormat;
