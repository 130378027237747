import React from "react";
import "./framebox.scss";

const FrameBox = ({ children, transparent, title, variant, className }) => {
  return (
    <div className={`silver-framebox ${className || ""}`}>
      <fieldset className="silver-fieldset">
        {title && (
          <legend className={variant ? "small" && "smaller-legend" : ""}>
            {title}
          </legend>
        )}
        <div className={`${transparent ? "transparent" : ""} frame-wrapper`}>
          {children}
        </div>
      </fieldset>
    </div>
  );
};

export default FrameBox;
