import { createSlice } from "@reduxjs/toolkit";
import {
  getActiveClients,
  getAllClients,
  getSearchClient,
  getRegisteredClients,
  getDashboardCardDetails,
  getOverAllClients,
  getIndividualClients,
  getBusinessClients,
} from "../action/clientAction";

const initialState = {
  activeClients: [],
  registeredClients: [],
  dashboardCardDetails: [],
  allClients: [],
  overAllClients: [],
  individualClients: [],
  businessClients: [],
  clientMaster: [],
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getActiveClients.fulfilled, (state, action) => {
      // Add user to the state array
      state.activeClients = action.payload?.data || [];
      state.clientMaster = action.payload?.clientMaster || [];
    });
    builder.addCase(getSearchClient.fulfilled, (state, action) => {
      // Add user to the state array
      state.allClients = action.payload;
    });
    builder.addCase(getAllClients.fulfilled, (state, action) => {
      // Add user to the state array
      state.allClients = action.payload;
    });
    builder.addCase(getOverAllClients.fulfilled, (state, action) => {
      // Add user to the state array
      state.overAllClients = action.payload;
    });
    builder.addCase(getRegisteredClients.fulfilled, (state, action) => {
      // Add user to the state array
      state.registeredClients = action.payload;
    });
    builder.addCase(getDashboardCardDetails.fulfilled, (state, action) => {
      // Add user to the state array
      state.dashboardCardDetails = action.payload;
    });
    builder.addCase(getIndividualClients.fulfilled, (state, action) => {
      // Add user to the state array
      state.individualClients = action.payload;
    });
    builder.addCase(getBusinessClients.fulfilled, (state, action) => {
      // Add user to the state array
      state.businessClients = action.payload;
    });
  },
});

export default clientSlice.reducer;
