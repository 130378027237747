const header = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "role", label: "Role" },
  { id: "doj", label: "Date of joining" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

export { header };
