import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Badge, Menu, MenuItem, Avatar } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  ManagePack,
  Notepad,
  ClientNav,
  Employees,
  InvoiceNav,
} from "../../images";
import "./notificaton.scss";
import { dateTimeFormatter } from "../../utils/common";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  button: {
    minWidth: "unset",
    "& svg": {
      color: "#0067b8",
      fontSize: "2rem",
    },
  },
  icon: {
    display: "inline-flex",
  },
  badge: {
    right: "2px",
    top: "-10px",
  },
  error: {
    backgroundColor: "#E41F3A",
  },
  disabled: {
    background: "#F0F0F0",
  },
});

const Notification = (props) => {
  const classes = useStyles(props);
  const navigate = useNavigate();
  const { disable, handleClick, badgeText, notifications, onMenuClick } = props;
  const [anchor, setAnchor] = React.useState(null);

  const showAvatar = (type) => {
    switch (type) {
      case "client":
        return ClientNav;
      case "employee":
        return Employees;
      case "contract":
        return Notepad;
      case "invoice":
        return InvoiceNav;
      case "payment":
        return ManagePack;
      default:
        return;
    }
  };
  return (
    <>
      <Button
        disabled={disable}
        className={`${classes.button}`}
        onClick={(e) => {
          e.stopPropagation();
          setAnchor(e.currentTarget);
          if (handleClick) handleClick();
        }}
        // disableRipple
        // disableElevation
        classes={{
          disabled: classes.disabled,
        }}
      >
        <NotificationsIcon />
        {badgeText && (
          <Badge
            badgeContent={badgeText}
            color="error"
            classes={{ badge: classes.badge, colorError: classes.error }}
            overlap="circular"
          />
        )}
      </Button>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={(e) => {
          setAnchor(null);
          e.stopPropagation();
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {notifications.length > 0 ? (
          notifications?.map(
            (option, index) =>
              index < 5 && (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    setAnchor(null);
                    e.stopPropagation();
                    if (onMenuClick) onMenuClick(option);
                  }}
                  className={`${
                    option?.view_status === "Not Viewed" &&
                    "notify-unread-menuitem"
                  } notify-border`}
                >
                  <div className="notify-tile">
                    <Avatar
                      alt={option?.notification_type}
                      src={showAvatar(option.notification_type)}
                      className="notify-avatar"
                    />
                    <div className="message-tile">
                      <div className={`notify-message`}>{option.message}</div>
                      <div className={`notify-time`}>
                        {dateTimeFormatter(option.createdAt)}
                      </div>
                    </div>
                  </div>
                </MenuItem>
              )
          )
        ) : (
          <MenuItem>
            <div className="message-tile">
              <div className="notify-message no-notify">
                No new notification
              </div>
            </div>
          </MenuItem>
        )}
        {notifications?.length > 0 && notifications?.length > 5 && (
          <div
            className="notify-see-all"
            onClick={() => {
              setAnchor(null);
              navigate("/finlo/notifications");
            }}
          >
            See all
          </div>
        )}
      </Menu>
    </>
  );
};

export default Notification;
