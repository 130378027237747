import React, { useEffect, useMemo, useState } from "react";
import { Labelbox } from "../../components";
import { Grid } from "@material-ui/core";
import { CLIENT_ACTION } from "../../redux/action";
import ContractPayment from "../contract_payment";
import ContractProposal from "../contract_proposal";
import { useSelector, useDispatch } from "react-redux";
import Empty from "antd/es/empty";

const MangeClientRole = () => {
  const dispatch = useDispatch();

  const {
    client: { clientMaster, activeClients },
  } = useSelector((state) => state);

  const [fieldValue, setFieldValue] = useState({
    client_id: "",
    activity_id: "",
  });

  const USER_DATA = useMemo(() => {
    return activeClients.find((d) => d.client_id === fieldValue.client_id);
  }, [fieldValue.client_id, activeClients]);

  const activity = [
    { id: "contract", value: "Contract" },
    { id: "invoice", value: "Invoice" },
  ];

  useEffect(() => {
    if (!clientMaster?.length) dispatch(CLIENT_ACTION.getActiveClients());
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item lg={3} sm={4} md={3} xs={12}>
          <Labelbox
            type="select"
            dropdown={clientMaster}
            labelname="Select Client"
            value={fieldValue?.client_id}
            changeData={(value) =>
              setFieldValue((prevState) => ({ ...prevState, client_id: value }))
            }
          />
        </Grid>
        <Grid item lg={3} sm={4} md={3} xs={12}>
          <Labelbox
            type="select"
            dropdown={activity}
            labelname="Select Activity"
            value={fieldValue?.activity_id}
            changeData={(value) =>
              setFieldValue((prevState) => ({
                ...prevState,
                activity_id: value,
              }))
            }
          />
        </Grid>
      </Grid>

      {fieldValue?.activity_id === "contract" ? (
        <ContractProposal userId={fieldValue.client_id} USER_DATA={USER_DATA} />
      ) : fieldValue?.activity_id === "invoice" ? (
        <ContractPayment userId={fieldValue.client_id} USER_DATA={USER_DATA} />
      ) : (
        <Empty />
      )}
    </>
  );
};
export default MangeClientRole;
